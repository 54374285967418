.sale {
    box-sizing: border-box; padding: 0.13in; text-align: center;
    margin: 0 auto;
    background: #FFF; border: 1px; width: 3.2in; box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);

    .datosempresa { padding: 0.15in 0.04in; border-bottom: 1px solid #000; }
    .notaremision img {width: 60%; max-width: 60%;}
    .folio { text-align: left;padding: 0.15in 0.04in;border-bottom: 1px solid #000; }
    .items { padding: 0.15in 0.04in;border-bottom: 1px solid #000; }

    table { font-size: 85%; table-layout: liquid; width: 100%; }
    table { border-collapse: separate; border-spacing: 2px; }
    th, td { border-width: 0; padding: 0.05em; position: relative; text-align: left; }
    th, td { border-style: solid; }
    th { background: #EEE; border-color: #BBB; }
    td { border-color: #DDD; }
    thead {font-weight: bold;}

    .totales { text-align: right;padding: 16px 10px; }
    .totales .cantidadLetra { text-align: center;padding: 0; }
    .totales .totalItems { text-align:center;padding:0; font-weight: bold}
    .paymentType {text-align: left; padding: 3%;}
}

@media print {
    * { -webkit-print-color-adjust: exact; }
    html { background: none; padding: 0; }
    body { box-shadow: none; margin: 0; }
    .page { box-shadow: none; }
    span:empty { display: none; }
    // .sale {width: auto}
}