input {
    cursor: pointer;
}
.full-page-loader {
    float: left;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000030;
}
.full-page-loader-file {
    float: left;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #00000030;
    flex-flow: column;
    gap: 16px;
    span {
        // background: #fffffff0;
        color: #fff;
        font-size: 26px;
        border-radius: 8px;
        font-weight: bold;
        :first-child {
            margin-top: 20px;
        }
    }
}
.header-item-project-name {
    color: white;
}
.mrow {
    flex-flow: row !important;
    align-items: center !important;
}

.ellipsis {
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
}
.cw {
    margin-top: 28px;
    width: 36rem;
    height: 32px;
}
.login {
    .logo {
        padding: 24px 0;
    }

    h1 {
        font-size: 28px !important;
        padding: 24px 0 16px 0;
        line-break: 200px;
    }

    h2 {
        font-size: 18px;
    }

    .login-signup {
        text-align: center;
    }

    .login-copyright {
        padding: 32px 0;
        color: #9a9a9b;
        text-align: center;
    }
}

.signup {
    .wrapper {
        width: 650px;

        @media screen and (max-width: 800px) {
            width: auto;
        }
    }

    .form-actions {
        button {
            width: 100%;
        }
    }

    .text-align-left {
        text-align: left;
    }
}

.required-error {
    border: 1px solid red;
}

.draftjs {
    min-height: 250px;
}
.global-dialog {
}

.title-mini {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    color: #abb2b9;
}
.card-example {
    margin: 12px 0px;
    width: 280.5px;
    height: 292.55px;
    border-radius: 16px;
    overflow: hidden;

    img {
        max-width: 280.5px;
        max-height: 292.55px;
        background-size: cover;
        border-radius: 16px;
    }
}
.form {
    .form-group {
        margin: 16px 0;

        .form-control,
        select {
            padding: 8px 12px !important;
            // font-size: 14px !important;
            // font-weight: 200 !important;
            height: 52px !important;
        }
    }
}

.form-group {
    margin: 16px 0;

    .form-control,
    select {
        padding: 8px 12px !important;
        // font-size: 14px !important;
        // font-weight: 200 !important;
        height: 38px !important;
    }
}

.table-borderless > tbody > tr > td {
    border: none;
}

.cart-options {
    margin-bottom: 24px;
}

.breadcrumb {
    margin-top: 24px;
    font-size: 12px;
}

.flex-fill {
    a {
        color: #bababa;

        &:hover {
            color: #ff5a5f;
        }
    }
}

.configuration-nav {
    nav {
        a {
            margin: 0 16px;
            color: #495057;

            &:hover,
            &.active {
                color: #24bba5;
            }
        }
    }
}

.collections-create-form,
[class*="collections-create-form"] {
    display: flex;
}

.collections-token,
[class*="collections-token"] {
    display: flex;
}
.collections-minting,
[class*="collections-minting"] {
    display: flex;
}
.collections-settings,
[class*="collections-settings"] {
    display: flex;
}
.collections-moon,
[class*="collections-moon"] {
    display: flex;
}
.collections-attributes,
[class*="collections-attributes"] {
    display: flex;
}
.collections-layers,
[class*="collections-layers"] {
    display: flex;
}
.collections-funds,
[class*="collections-funds"] {
    display: flex;
}
.collections-collectible,
[class*="collections-collectible"] {
    display: flex;
}
.collections-claim,
[class*="collections-claim"] {
    display: flex;
}
.collections-tab,
[class*="collections-tab"] {
    display: flex;
}
.collections-dashboard,
[class*="collections-dashboard"] {
    display: flex;
}
.collections-ticketing,
[class*="collections-ticketing"] {
    display: flex;
}
.collections-choose-type,
[class*="collections-choose-type"] {
    display: flex;
}
.team-members,
[class*="team-members"] {
    display: flex;
}
.collections-ordinals,
[class*="collections-ordinals"] {
    display: flex;
}
.collections-reclaim,
[class*="collections-reclaim"] {
    display: flex;
}
.collections {
    // create.js
    &-create {
        &-form {
            flex-flow: column;
            &-row {
                flex-flow: row;
                &-content {
                    flex: 1;
                    flex-flow: column;
                    align-items: center;
                    justify-content: center;
                }
                &-img {
                    flex: 0;
                    min-width: 250px;
                    min-height: 100%;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-flow: column;
                    margin-right: 1rem;
                    position: relative;
                    p {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        /* #100820 */

                        color: #100820;
                    }
                }
                &-ico {
                    position: absolute;
                    top: 4rem;
                    right: 1rem;
                    padding: 9px;
                    width: 41px;
                    height: 42.57px;
                    background: #833cfc;
                    box-shadow: 0px 1px 2px rgb(0 0 0 / 5%);
                    border-radius: 21px;
                    color: white;
                    font-size: 18px;
                    align-items: center;
                    cursor: pointer;
                }
            }
            &-upload {
                flex: 1;
                flex-flow: column;
                min-width: 100%;
                justify-content: center;
                align-items: center;
                margin-top: 12px;
                &-title {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 41px;
                    /* identical to box height */

                    /* #100820 */

                    color: #100820;
                    width: 100%;
                    padding-top: 12px;
                }
                &-drop {
                    width: 100%;
                    height: 25rem;
                    border: 2px dashed #b6a8f8;
                    flex-flow: column;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border-radius: 12px;
                    &-title {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 19.5px;
                        line-height: 29px;

                        color: #495057;
                    }
                    &-subtitle {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        /* identical to box height */
                        display: block;
                        color: #495057;
                        b {
                            margin-left: 2px;
                        }
                        span {
                            color: #7458e1;
                        }
                    }
                    &-ico {
                        font-size: 50px;
                    }
                }
                &-container {
                    flex-flow: column;
                    width: 100%;
                    &-dropzone {
                        flex: 1;
                    }
                    &-list {
                        flex: 1;
                        flex-flow: column;
                        li {
                            font-family: "Inter";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            /* or 143% */

                            /* gray/500 */

                            color: #6b7280;
                        }
                        span {
                            color: #7458e1;
                        }
                    }
                }
            }
            &-bottom {
                justify-content: flex-end;
                flex: 1;
                margin-top: 12px;
                align-items: end;
                position: fixed;
                width: 100%;
                bottom: 3em;
                left: 0px;
                padding-right: 4rem;
                &-label {
                    align-self: center;
                    margin: 0;
                    margin-right: 6px;
                    font-family: "Poppins";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;

                    color: #000000;
                }
            }
        }
    }
    &-choose-type {
        position: relative;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-clip: border-box;
        padding: 0;
        width: 300px;
        margin: 12px;
        border-radius: 10px;
        display: inline-block;
        &-row-center {
            display: flex;
            justify-content: center;
        }
        &-mobile {
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 15px;
        }
        &-card {
            padding: 15px 15px;
            align-items: center;
            gap: 15px;
            align-self: stretch;
            border-radius: 16px;
            background: #f8f5ff;
            flex-flow: row;
            &-img {
                width: 56.245px;
                height: 56.245px;
                border-radius: 3.479px;
                background: #fff;
            }
            &-body {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                flex: 1 0 0;
                &-title {
                    color: #100820;
                    font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
                &-subtitle {
                    color: #595959;
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 14.4px; /* 120% */
                }
            }
        }
    }
    &-token {
        flex-flow: row;
        &-form {
            flex: 0 1;
            min-width: 250px;
            flex-flow: column;
            &-title {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                /* #100820 */

                color: #100820;
            }
            &-border {
                margin: 0;
                flex-flow: column;
                // border: 1px solid #d1d8db;
                // border-radius: 5px;

                &-container {
                    // flex-flow: row;
                    // border: 1px solid #d1d8db;
                    // border-radius: 5px;
                    margin: 4px 0px;
                    padding: 8px 16px;
                    justify-content: space-between;
                    align-items: center;
                    /* white */
                    height: 38px;
                    background: #ffffff;
                    /* Default/gray/300 */

                    border: 1px solid #d1d5db;
                    /* shadow/sm */

                    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
                    border-radius: 18px;
                    p {
                        margin: 0;
                    }
                    &-title {
                        // margin: 0;
                        // font-family: "Montserrat";
                        // font-style: normal;
                        // // font-weight: 600;
                        // font-size: 14px;
                        // line-height: 17px;
                        // /* identical to box height */

                        // color: #000000;
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        color: #374151;
                    }
                    &-icon {
                        // font-weight: lighter;
                        // font-size: 26px;
                        // line-height: 17px;
                        // color: #d1d8db;
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        color: #374151;
                    }
                }
                &-group {
                    flex-flow: column;
                    // border: 1px solid #d1d8db;
                    // border-radius: 5px;
                    margin: -12px 0px 0px 0px;
                    padding: 8px 16px;
                    justify-content: space-between;

                    background: #ffffff;
                    /* /shadow/md */

                    box-shadow:
                        0px 4px 6px -1px rgba(0, 0, 0, 0.1),
                        0px 2px 4px -1px rgba(0, 0, 0, 0.06);
                    border-radius: 6px;
                }
                &-checkbox {
                    flex-flow: row;
                    justify-content: space-between;
                    align-items: center;
                    .custom-checkbox .custom-control-label::before {
                        border-radius: 50%;
                        transform: scale(1.5);
                        top: 3px;
                    }
                    .custom-checkbox .custom-control-label::after {
                        transform: scale(1.5);
                        top: 3px;
                        display: none;
                    }
                    .custom-control-label {
                        // font-family: "Montserrat";
                        // font-style: normal;
                        // font-weight: 400;
                        // font-size: 12px;
                        // line-height: 17px;
                        // /* identical to box height */

                        // color: #000000;
                        // padding-left: 4px;

                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        /* Default/gray/700 */

                        color: #374151;
                    }
                    .counter {
                        // font-family: "Montserrat";
                        // font-style: normal;
                        // font-weight: 400;
                        // font-size: 14px;
                        // line-height: 17px;
                        // /* identical to box height */

                        // text-align: right;

                        // color: #d1d1d1;
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        text-align: right;

                        /* Default/gray/400 */

                        color: #9ca3af;
                    }
                }
            }
            .form-group {
                width: 100%;
                margin: 8px 0;
            }
            &.auto {
                height: auto !important;
            }
        }
        &-resume {
            padding: 0px 8px;
            flex-flow: column;
            flex: 1 1;
            &-top {
                flex-flow: row;
                justify-content: space-between;

                //  temporal data
                padding: 14px 0px;
                margin-top: -80px;
                &-title {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 24px;
                    margin-top: 4px;
                    color: #000000;
                }
                &-actions {
                    flex: 0;
                    button {
                        flex: 1;
                        margin-left: 4px;
                    }
                }
            }
            &-card {
                width: 114px !important;
                height: auto !important;
                padding: 0;
                margin-right: auto !important;
                &-video {
                    width: "100%";
                    height: "100%";
                }
                &.fill {
                    box-shadow: none;
                    &:hover {
                        box-shadow: none;
                    }
                }
            }
            &-list {
                &-infinite {
                    display: flex;
                    flex-wrap: wrap;
                    &::after {
                        content: "";
                        flex: auto;
                        flex-basis: 200px; /*your item width*/
                        flex-grow: 0;
                    }
                }
            }
        }
        &-clearfix {
            height: 300px;
        }
    }
    &-minting {
        flex-flow: column;
        margin-bottom: 6em;
        &-top {
            flex: 1;
            width: 100%;
            flex-flow: column;
            &-form {
                flex-flow: row;
                &-img {
                    flex-flow: column;
                    width: auto;
                    justify-content: center;
                    .collections-create-form-row-img {
                        margin-left: 0;
                        margin-right: 3em;
                    }
                }
            }
            &-group {
                flex-flow: row;
                align-items: center;
                justify-content: center;
            }
            &-display {
                flex-flow: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 500px;
                margin-top: 4rem;
                margin-left: auto;
                margin-right: auto;
                border-radius: 6px;
                border: #000 solid 5px;
                &.tablet {
                    // width: 820px;
                    // height: 1180px;
                    width: 590px;
                    height: 410px;
                    .collections-minting-top-display-logo {
                        height: 10rem;
                    }
                }
                &.pc {
                    width: 90vw;
                    height: 60vw;
                    .collections-minting-top-display-logo {
                        height: 12rem;
                    }
                }

                &.phone {
                    // width: 390px;
                    // height: 844px;
                    width: 260px;
                    height: 420px;
                    .collections-minting-top-display-logo {
                        height: 8rem;
                    }
                    .collections-minting-top-display-connect {
                        width: 90%;
                        .collections-minting-top-display-connect-primary {
                            width: 90%;
                        }
                    }
                }
                &.card {
                    height: 100%;
                    width: 100%;
                    margin: 0;
                    border: #00000030 solid 0.5px;
                    padding: 0;
                    .collections-minting-top-display {
                        &-connect {
                            width: 160px;
                            height: 32px;
                            &-primary {
                                width: 150px;
                                height: 20px;
                            }
                        }
                        &-logo {
                            top: 4rem;
                        }
                    }
                }
                &-container {
                    flex-flow: row;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    width: 100%;
                    height: 100%;
                }
                &-background {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 0;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                &-connect {
                    width: 210px;
                    height: 70px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    &-black {
                        background: #000;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 6px;
                    }
                    &-primary {
                        background: #5831c5;
                        width: 180px;
                        height: 40px;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 6px;

                        span {
                            color: white;
                            text-transform: uppercase;
                        }
                    }
                }
                &-logo {
                    position: absolute;
                    left: 50%;
                    top: 10rem;
                    z-index: 1;
                    transform: translate(-50%, -50%);
                    width: auto;
                    height: 50px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                &-powered {
                    flex-flow: row;
                    align-items: center;
                    z-index: 1;
                    .custom-footer {
                        left: 50%;
                    }
                }
            }
        }
        &-bot {
            height: 300px;
            width: 100%;
            align-items: center;
            justify-content: center;
            position: relative;
            &-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                z-index: 0;
            }
            &-button {
                background: white;
                padding: 16px 12px;
                border-radius: 4px;
                position: absolute;
                z-index: 0;
                button {
                    margin: 0 !important;
                }
            }
        }

        &-sample {
            height: auto;
            width: 100vw;
            position: relative;
            display: block;
            margin-left: -6rem;
            &-land {
                position: absolute;
                z-index: 0;
                left: 16%;
                top: 9.5vw;
                height: 37vw;
                width: 50vw;
                &-logo {
                    position: absolute;
                    left: 25vw;
                    top: 5vw;
                    transform: translate(-50%, -50%);
                    width: auto;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &-img {
                        width: auto;
                        height: 60px;
                        object-fit: fill;
                        /* position: absolute; */
                        z-index: 1;
                    }
                }
            }
            &-phone {
                position: absolute;
                z-index: 0;
                right: 15%;
                top: 18vw;
                height: 30vw;
                width: 14vw;
                &-logo {
                    position: absolute;
                    left: 7vw;
                    top: 5vw;
                    transform: translate(-50%, -50%);
                    width: auto;
                    height: 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &-img {
                        width: auto;
                        height: 40px;
                        object-fit: fill;
                        /* position: absolute; */
                        z-index: 1;
                    }
                }
            }
            &-image {
                width: 100vw;
                height: auto;
                object-fit: cover;
                position: absolute;
                z-index: 0;
            }
            &-image1 {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
            &-image2 {
                width: 100%;
                height: auto;
                object-fit: cover;
            }
        }
        &-control {
            flex-flow: row;
            cursor: pointer;
            align-items: center;
            padding: 9px 0px;
            height: 38px;

            /* white */

            background: #ffffff;
            /* Default/gray/300 */

            border: 1px solid #d1d5db;
            /* shadow/sm */

            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
            border-radius: 18px;
            overflow: hidden;
            &.square {
                border-radius: 0px;
                .collections-minting-control-pre {
                    border-radius: 0px;
                }
            }
            .pre {
                border-radius: 18px 18px 18px 18px;
            }
            &.small {
                height: 26px;
                padding: 4px 0px;
            }
            &-pre {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 9px 17px;

                width: auto;
                height: 38px;

                /* #F8F5FF */

                background: #f8f5ff;
                border-top: none;
                border-bottom: none;
                border-left: none;
                border-right: 1px solid #d1d5db;

                box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.05);
                border-radius: 19px;
                span {
                    /* text-sm/leading-5/font-medium */

                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    /* Purple */

                    color: #8d78fb;
                }
            }
            &-post {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 9px 17px;

                width: auto;
                height: 38px;

                /* #F8F5FF */

                background: #f8f5ff;
                border-top: none;
                border-bottom: none;
                border-right: none;
                border-left: 1px solid #d1d5db;
                span {
                    /* text-sm/leading-5/font-medium */

                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    /* Purple */

                    color: #8d78fb;
                }
            }
            &-input {
                background: white;
                border: none;
                box-shadow: none;
                flex: 1;
                padding-left: 8px;
                .form-control {
                    border: none;
                    height: 100% !important;
                    box-shadow: none;
                }
            }
            &-pretext {
                flex: 1;
            }
            &-posttext {
                margin-left: 6px;
                align-items: center;
                justify-content: center;
                span {
                    // font-family: "Montserrat";
                    // font-style: normal;
                    // font-weight: 700;
                    // font-size: 16px;
                    // line-height: 20px;
                    // /* identical to box height */

                    // color: #000000;
                    /* text-sm/leading-5/font-normal */

                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    /* gray/500 */

                    color: #6b7280;
                }
            }
            &-icon {
                padding: 9px 13px;
                gap: 4px;

                width: 46px;
                height: 38px;

                background: #ffffff;
                border-radius: 6px;
                font-size: 20px;
                color: #9ca3af;
                &.active {
                    color: #34d399;
                }
            }
        }
    }
    &-settings {
        flex-flow: column;
        flex: 1;
        &-header {
            width: 100%;
            flex: 1;
            align-items: center;
            justify-content: flex-start;
            padding-bottom: 10px;
            &-container {
                border-bottom: 2px solid #d1d8db;
                margin-bottom: 18px;
                max-width: 100%;
                overflow-x: auto;
                overflow-y: hidden;
                &::-webkit-scrollbar {
                    width: 4px;
                    height: 4px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px transparent;
                    border-radius: 10px;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #8d78fb;
                    border-radius: 10px;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #7762e1;
                }
            }
            &-item {
                // font-family: "Montserrat";
                // font-style: normal;
                // font-weight: 700;
                // font-size: 18px;
                // line-height: 22px;
                // color: #8b8b8b;

                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                /* identical to box height, or 125% */

                text-align: center;

                /* #100820 */

                color: #100820;

                cursor: pointer;
                height: 100%;
                padding: 16px 28px;
                margin-bottom: -2px;
                &.disabled {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    /* identical to box height, or 125% */

                    text-align: center;

                    color: #d1d8db;
                    cursor: not-allowed;
                }
                &.active {
                    border-bottom: 2px solid #833cfc;
                    color: #833cfc;
                }
            }
            &-button {
                margin-left: auto;
            }
        }
        &-item {
            flex: 1;
            flex-flow: column;
        }
        &-row {
            flex-flow: row;
            &.title {
                align-items: center;
                margin-top: 20px;
            }
            &-title {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #9ca3af;
                cursor: pointer;
            }
        }
        &-tab {
            &-title {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 700;
                font-size: 35px;
                line-height: 41px;
                /* identical to box height */
                padding-left: 12px;

                /* #100820 */

                color: #100820;
                &.mini {
                    font-size: 24px;
                    line-height: 28px;
                }
            }
            &-subtitle {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                /* or 143% */

                /* gray/500 */
                padding-left: 12px;

                color: #6b7280;
            }
            &-nav {
                position: relative;
                margin-bottom: 2rem;
            }
            &-button {
                align-self: flex-end;
                justify-self: flex-end;
                position: absolute;
                right: 0;
            }
            &-super-title {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 800;
                font-size: 38px;
                line-height: 59px;
                color: #5a6275;
            }
            &-banner {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 22px;
                /* identical to box height */

                color: #000000;
                margin-left: 26px;
                &-dot {
                    background: white;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    margin-right: 12px;
                    &.yellow {
                        background: #ffc701;
                    }
                    &.green {
                        background: green;
                    }
                    &.red {
                        background: red;
                    }
                }
            }
        }
        &-container {
            flex-flow: row;
        }
        &-image {
            flex: 0 1;
            max-height: 400px;
            width: 100%;
            margin-top: 16px;
            margin-left: 0;
            justify-content: flex-start;

            img {
                object-fit: contain;
            }
        }
    }
    &-moon {
        flex-flow: column;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 200px);
        &-title {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 35px;
            line-height: 41px;
            /* identical to box height */

            text-align: center;

            /* #100820 */

            color: #100820;

            margin-bottom: 2em;
        }
        &-img {
            width: 400px;
            height: 400px;
            // border-radius: 50%;
            // overflow: hidden;
        }
        &-airwave {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            text-align: center;

            /* #100820 */

            color: #100820;
            margin-top: 3rem;
        }
        &-subtitle {
            /* text-sm/leading-5/font-normal */

            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            /* or 143% */

            text-align: center;

            /* gray/500 */

            color: #6b7280;
            margin-top: 3rem;
        }
        &-copy {
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 4px;

            width: 320px;
            height: 38px;

            /* Inside auto layout */

            flex: none;

            margin-bottom: 12px;
            flex-grow: 0;
            &-inner {
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                padding: 9px 0px;

                width: 320px;
                height: 38px;

                /* #F8F5FF */

                background: #f8f5ff;
                /* Default/gray/300 */

                border: 1px solid #d1d5db;
                /* shadow/sm */

                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
                border-radius: 18px;

                /* Inside auto layout */

                flex: none;
                order: 1;
                align-self: stretch;
                flex-grow: 0;
                overflow: hidden;
            }
            &-box {
                flex-direction: row;
                align-items: center;
                padding: 9px 12px 9px 13px;
                gap: 8px;

                width: 274px;
                height: 38px;

                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 1;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                /* Default/gray/500 */

                color: #6b7280;

                white-space: nowrap;
                overflow: hidden;
            }
            &-icon {
                flex-direction: row;
                align-items: center;
                padding: 9px 13px;
                gap: 4px;

                width: 46px;
                height: 38px;

                background: #ffffff;
                box-shadow: -1px 0px 2px rgba(0, 0, 0, 0.05);
                border-radius: 6px;

                /* Inside auto layout */

                flex: none;
                order: 1;
                flex-grow: 0;
                cursor: pointer;
            }
        }
    }
    &-attributes {
        flex-flow: column;
        padding: 24px 0px 0px 0px;
        max-width: 100%;
        width: 100%;
        ul {
            padding: 0;
        }
        &-title {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 47px;
            color: #5a6275;
            h2 {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 36px;
                line-height: 47px;
                color: #5a6275;
            }
        }
        &-row {
            flex-flow: column;
            margin: 12px 0;
            // overflow-x: auto;

            /* width */
            &::-webkit-scrollbar {
                width: 10px;
                height: 10px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px transparent;
                border-radius: 10px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #8d78fb;
                border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #7762e1;
            }
            &-title {
                flex-flow: row;

                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 26px;
                /* identical to box height */

                color: #5a6275;

                align-items: end;
                justify-content: flex-start;
            }
            &-body {
                flex-flow: row;
                justify-content: flex-start;
                padding-bottom: 24px;
                overflow-x: auto;

                /* width */
                &::-webkit-scrollbar {
                    width: 10px;
                    height: 10px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px transparent;
                    border-radius: 10px;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #8d78fb;
                    border-radius: 10px;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #7762e1;
                }
            }
            &-item {
                flex-flow: column;
                margin-right: 12px;
                &-card {
                    width: 184px;
                    height: 184px;

                    border: 1px solid #d1d8db;
                    border-radius: 5px;
                    &.ico {
                        align-items: center;
                        justify-content: center;
                        font-size: 60px;
                        color: #808080a1;
                    }
                }
                &-label {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 29px;

                    color: #000000;

                    margin-top: 12px;
                }
                &-estimated {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 17px;
                    /* identical to box height */

                    color: #000000;

                    margin-top: 6px;
                }
            }
        }
    }
    &-layers {
        flex-flow: column;
        padding: 24px 0px 0px 0px;
        max-width: 100%;
        flex: 1;
        ul {
            padding: 0;
        }
        &-title {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 35px;
            line-height: 41px;
            /* identical to box height */

            /* #100820 */

            color: #100820;
        }
        &-subtitle {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            /* #100820 */

            color: #100820;
            width: 100%;
        }
        &-label {
            /* text-sm/leading-5/font-normal */

            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            /* or 143% */

            /* gray/500 */

            color: #6b7280;
        }
        &-row {
            // flex-flow: row;
            // border: 1px solid #d9d9d9;
            // margin-top: 20px;
            // align-items: center;
            // justify-content: flex-start;
            // padding: 8px;

            /* Auto layout */

            display: flex;
            flex-flow: row;
            align-items: center;
            padding: 9px 0px;
            justify-content: flex-start;
            width: 390px;
            height: 38px;

            /* white */

            background: #ffffff;
            /* Default/gray/300 */

            border: 1px solid #d1d5db;
            /* shadow/sm */

            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
            border-radius: 18px;
            margin-top: 20px;
            cursor: pointer;
            z-index: 2;
            &-ico {
                flex: 0;
                font-size: 20px;
                color: #9ca3af;

                margin-left: 1.5rem;
                margin-right: 1.5rem;
            }
            &-title {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                /* Default/gray/500 */

                color: #6b7280;
                /* Inside auto layout */

                // flex: none;
                // order: 1;
                // flex-grow: 0;
                flex: 1;
                p {
                    margin: 0;
                }
            }
            &-arrow {
                flex: 0;
                margin-right: auto;
                font-size: 20px;
                color: #9ca3af;
                cursor: pointer;
                margin-left: 1.5rem;
                margin-right: 1.5rem;
                position: relative;
                // button {
                //     flex: 0;
                //     margin-right: auto;
                //     font-size: 20px;
                //     color: #9ca3af;
                //     margin-left: 1.5rem;
                //     margin-right: 1.5rem;
                //     height: 36px;
                //     width: 36px;
                //     max-height: 100%;
                //     align-items: center;
                //     justify-content: center;
                //     display: flex;
                //     background: transparent;
                //     border: none;
                //     &:hover {
                //         background: transparent !important;
                //         color: #a066ff;
                //     }
                // }
            }
        }
        &-dialog {
            flex-flow: column;

            input[type="range"] {
                accent-color: #556ee6;
                background: #f6f6f6;
                outline: none;
                transition: background 450ms ease-in;
            }
            &-title {
                // font-family: "Montserrat";
                // font-style: normal;
                // font-weight: 700;
                // font-size: 26px;
                // line-height: 36px;
                // color: #000000;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 700;
                font-size: 35px;
                line-height: 41px;
                /* identical to box height */

                /* #100820 */

                color: #100820;
            }
            &-token {
                // font-family: "Montserrat";
                // font-style: normal;
                // font-weight: 400;
                // font-size: 14px;
                // line-height: 27px;
                // /* identical to box height */

                // color: #000000;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                /* identical to box height, or 125% */

                /* #100820 */

                color: #100820;
            }
            &-strong {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                /* identical to box height, or 125% */

                /* #100820 */

                color: #100820;
            }
            &-subtitle {
                /* text-sm/leading-5/font-normal */

                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                /* gray/500 */

                color: #6b7280;
            }
            &-table {
                margin-top: 4px;
                // padding: 0px 0px;

                background: #f8f5ff;
                border: 1px solid #d1d5db;
                box-shadow:
                    inset 0px -6px 6px 1px rgba(0, 0, 0, 0.05),
                    inset 0px -2px 2px rgba(0, 0, 0, 0.05);
                border-radius: 12px;
                padding: 20px 4px 0px 20px;

                // .end {
                //     text-align: end;
                // }
                table {
                    width: 100%;
                }
                thead {
                    th {
                        // font-family: "Montserrat";
                        // font-style: normal;
                        // font-weight: 700;
                        // font-size: 14px;
                        // line-height: 27px;
                        // /* identical to box height */

                        // color: #000000;
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        /* #100820 */

                        color: #100820;

                        width: 25%;
                    }
                }
                tbody {
                    td {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        /* #100820 */

                        color: #595959;

                        padding: 6px 0px;

                        .input-group {
                            justify-content: end;
                            input {
                                max-width: 120px;
                            }
                        }
                        .end {
                            margin: 0;
                            max-width: 50px;
                            // padding-left: 12rem;
                        }
                    }
                }
                &-card {
                    align-items: center;
                    img {
                        height: 40px;
                        width: 40px !important;
                    }
                    .ico {
                        font-size: 30px;
                    }
                    p {
                        margin: 0;
                        padding-left: 4px;
                        color: #100820;
                    }
                }
                &-header {
                    flex-flow: row;
                    align-items: center;
                    // justify-content: flex-end;
                    span {
                        font-family: "Montserrat";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 27px;
                        /* identical to box height */

                        color: #000000;
                    }
                }
            }
            &-display {
                background: #ffffff;
                border: 1px solid #d9d9d9;
                border-radius: 10px;
                flex-flow: column;
                padding: 22px 22px 40px 22px;
                margin-top: 20px;
                &-title {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 27px;
                    /* identical to box height */

                    color: #000000;
                }
                &-subtitle {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 27px;
                    /* identical to box height */

                    color: #000000;
                }
            }
        }
        &-container {
            flex-flow: row;
        }
        &-ltable {
            flex-flow: column;
            width: 70%;
        }
        &-preview {
            flex-flow: column;
            width: 30%;
            align-items: center;
            // justify-content: center;
        }
        &-rules {
            flex-flow: column;
            &-contain {
                flex-flow: column;
                &-row {
                    flex-flow: row;
                    &-input {
                        margin: 0px 8px;
                        min-width: 200px;
                        .form-group {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    &-funds {
        flex-flow: column;
        width: 100%;
        &-top {
            flex-flow: row;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-evenly;
            flex: 1;
        }
        &-card {
            flex: 1;
            max-width: 450px;
            min-width: 400px;
            background: white;
            border-radius: 6px;
            height: 208px;
            min-height: 208px;
            flex-flow: column;
            margin: 12px;
            align-items: center;
            grid-gap: 15px;
            gap: 15px;
            justify-content: center;
            box-shadow:
                0px 1px 3px rgb(0 0 0 / 10%),
                0px 1px 2px rgb(0 0 0 / 6%);
            &-top {
                flex-flow: column;
                align-items: center;
            }
            &-bot {
                flex-flow: column;
                align-items: center;
            }
            &-title {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 36px;
                line-height: 30px;
                /* or 32px */
                text-align: center;
                /* #595959 */
                color: #595959;
            }
            &-cripto {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 21px;
                line-height: 30px;
                /* identical to box height, or 19px */
                color: #6b7280;
                align-items: center;
                padding-top: 12px;
            }
            &-bold {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 700;
                font-size: 25px;
                line-height: 29px;
                text-align: center;

                /* #100820 */

                color: #100820;
            }
            &-subtitle {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                text-align: center;
                align-items: center;
                padding-top: 12px;

                /* gray/500 */

                color: #6b7280;
            }
            &-principal {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 32px;
                line-height: 31px;
                /* identical to box height, or 97% */

                text-align: center;

                /* Default/gray/700 */

                color: #374151;
            }
            &-second {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* or 143% */

                text-align: center;

                /* #595959 */

                color: #595959;
            }
            &-ico {
                color: #10b981;
                font-size: 64px;
            }
        }
        &-payout {
            padding: 30px;
            background: white;
            border-radius: 6px;
            flex-flow: column;
            width: 100%;
            height: auto;

            &-top {
                flex-flow: row;
                justify-content: space-between;
                &-title {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 32px;
                    line-height: 31px;
                    /* identical to box height, or 97% */

                    text-align: center;

                    /* Default/gray/700 */

                    color: #374151;
                }
            }
            &-table {
                width: 100%;
                min-width: 800px;
                table {
                    width: 100%;
                    table-layout: fixed;
                    overflow-wrap: break-word;

                    th,
                    td {
                        padding: 4px 12px;
                        text-align: left;
                    }
                }
                table th  {
                    padding: 30px 12px;
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    /* #100820 */

                    color: #100820;
                    padding: 4px 12px;
                }
                table td {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    /* gray/500 */

                    color: #6b7280;
                    padding: 8px 12px;
                }
                .to {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    /* #833CFC */

                    color: #833cfc;
                    cursor: pointer;
                }
                .txn_hash {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    /* #833CFC */

                    color: #833cfc;
                    cursor: pointer;
                }
                .status {
                    span {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 2px 12px;

                        width: 98px;
                        height: 24px;

                        background: #dcd9e8;
                        border-radius: 4px;

                        /* Inside auto layout */

                        flex: none;
                        order: 0;
                        flex-grow: 0;
                        font-family: "Inter";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        text-align: center;

                        /* gray/800 */

                        color: #1f2937;
                    }
                }
            }
        }
        &-modal {
            // width: 782px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 14px 14px 14px;
            gap: 32px;
            justify-content: center;
        }
    }
    &-collectible {
        flex-flow: column;
        padding: 30px;
        grid-gap: 30px;
        gap: 30px;
        background: #ffffff;
        box-shadow:
            0px 1px 3px rgb(0 0 0 / 10%),
            0px 1px 2px rgb(0 0 0 / 6%);
        border-radius: 12px;
        flex: 1;
        &-row {
            flex-flow: row;
        }
        &-img {
            height: 293px;
            width: 100%;
            .card-picture {
                height: 100%;
                width: 100%;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #dcd3ff;
                border-radius: 8px;
                color: white;
                font-size: 40px;
            }
        }
        &-textbox {
        }
    }
    &-ordinals {
        flex-flow: column;
        padding: 30px;
        grid-gap: 30px;
        gap: 30px;
        background: #ffffff;
        box-shadow:
            0px 1px 3px rgb(0 0 0 / 10%),
            0px 1px 2px rgb(0 0 0 / 6%);
        border-radius: 12px;
        flex: 1;
        &-row {
            flex-flow: row;
            gap: 30px;
        }
        &-inscription {
            flex-direction: column;
            align-items: flex-start;
            gap: 30px;
        }
        &-title {
            color: #100820;
            font-family: Roboto;
            font-size: 35px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        &-subtitle {
            color: #6b7280;

            /* text-sm/leading-5/font-normal */
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
        }
        &-box {
            cursor: pointer;
            height: 207px;
            padding: 30px 20px;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 30px;
            border-radius: 12px;
            border: 1px solid #a066ff;
            background: #f8f5ff;
            box-shadow:
                0px 0px 0px 1px rgba(132, 60, 252, 0.3),
                0px 1px 3px 0px rgba(0, 0, 0, 0.1);
            &-icon {
                width: 70px;
                height: 70px;
                flex-shrink: 0;
                align-items: center;
                justify-content: center;
                color: #8d78fb;
                font-size: 40px;
            }
            &-label {
                color: #100820;
                text-align: center;
                /* 22 */
                font-family: Roboto;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
        &-help {
            flex-flow: column;
            color: #6b7280;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 142.857% */
            &-more {
                color: #833cfc;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 20px;
            }
            &-sub {
                color: #6b7280;
                /* text-sm/leading-5/font-normal */
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 142.857% */
            }
        }
        &-inscribed {
            min-height: 232px !important;
        }
        &-transactions {
            flex-flow: row;
            gap: 20px;
            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px transparent;
                border-radius: 10px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #8d78fb;
                border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #7762e1;
            }

            &-box {
                width: 178px;
                padding: 30px 15px;
                flex-direction: column;
                align-items: center;
                gap: 30px;
                border-radius: 12px;
                background: #fff;
                cursor: pointer;
                /* /shadow/base */
                box-shadow:
                    0px 1px 2px 0px rgba(0, 0, 0, 0.06),
                    0px 1px 3px 0px rgba(0, 0, 0, 0.1);

                &::-webkit-scrollbar {
                    width: 4px;
                    height: 4px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 5px transparent;
                    border-radius: 10px;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #8d78fb;
                    border-radius: 10px;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #7762e1;
                }

                &.selected {
                    border: 1px solid #a066ff;
                    background: #f8f5ff;
                    box-shadow:
                        0px 0px 0px 1px rgba(132, 60, 252, 0.3),
                        0px 1px 3px 0px rgba(0, 0, 0, 0.1);
                }

                &-title {
                    text-align: center;
                    font-family: Roboto;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
                &-price {
                    color: #595959;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 89.188%; /* 26.756px */
                }
                &-satsvbyte {
                    text-align: center;
                    /* text-sm/leading-5/font-normal */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */
                    color: #6b7280;
                }
                &-info {
                    color: #6b7280;
                    text-align: center;
                    /* text-sm/leading-5/font-normal */
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */
                    flex-flow: column;
                }
                &-within {
                    color: #000;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                }
            }
        }
        &-view {
            padding: 30px;
            flex-direction: column;
            align-items: flex-start;
            gap: 70px;
            width: 100%;
            border-radius: 12px;
            background: #fff;

            /* /shadow/base */
            box-shadow:
                0px 1px 2px 0px rgba(0, 0, 0, 0.06),
                0px 1px 3px 0px rgba(0, 0, 0, 0.1);
            &-contain {
                flex-flow: row;
                align-items: flex-start;
                gap: 50px;
                align-self: stretch;

                &-img {
                    width: 320px;
                    height: 320px;
                    align-items: flex-start;
                    gap: 8.966px;
                    border-radius: 7.173px;
                    img {
                        width: auto;
                        height: 100%;
                    }
                    align-items: center;
                    justify-content: center;
                    color: #8d78fb;
                    font-size: 12rem;
                }
                &-body {
                    flex-flow: column;
                    align-items: flex-start;
                    gap: 30px;
                    flex: 1 0 0;
                    max-width: calc(100% - 320px);
                    &-detail {
                        flex-flow: column;
                        .mb-6 {
                            margin-bottom: 2rem;
                        }
                        .mt-12 {
                            margin-top: 4rem;
                        }
                        .mb-12 {
                            margin-bottom: 4rem;
                        }
                        &-status {
                            display: flex;
                            flex-flow: row;
                            justify-content: space-between;
                        }
                    }
                    &-title {
                        color: #100820;
                        font-family: Roboto;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 900;
                        line-height: normal;
                    }
                    &-subtitle {
                        color: #6b7280;
                        /* text-sm/leading-5/font-normal */
                        font-family: Inter;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px; /* 142.857% */
                    }
                    &-rows {
                        flex-flow: row;
                        align-items: flex-start;
                        gap: 30px;
                        align-self: stretch;
                        overflow: hidden;
                        width: 100%;
                        text-overflow: ellipsis;
                        &-title {
                            color: #000;
                            font-family: Roboto;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px; /* 142.857% */
                            width: 120px;
                            min-width: 120px;
                        }
                        &-text {
                            color: #6b7280;
                            /* text-sm/leading-5/font-normal */
                            font-family: Inter;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px; /* 142.857% */
                        }
                    }
                }
                &-explorer {
                    color: #833cfc;
                    text-align: right;
                    font-family: Roboto;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 142.857% */
                }
            }
        }
    }
    &-claim {
        flex-flow: row;
        gap: 30px;

        // padding: 30px;
        // grid-gap: 30px;
        // gap: 30px;
        // background: #ffffff;
        // box-shadow: 0px 1px 3px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 6%);
        // border-radius: 12px;
        flex: 1;
        &-img {
            min-width: 300px;
            max-width: 50%;
            &-container {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 30px;
                gap: 30px;
                /* Default/white */

                background: #ffffff;
                /* /shadow/base */

                box-shadow:
                    0px 1px 3px rgba(0, 0, 0, 0.1),
                    0px 1px 2px rgba(0, 0, 0, 0.06);
                border-radius: 12px;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
        &-detail {
            min-width: 50%;
            max-width: 50%;
            &-container {
                flex-flow: column;
                align-items: flex-start;
                padding: 30px;
                gap: 30px;

                /* Default/white */

                background: #ffffff;
                /* /shadow/base */

                box-shadow:
                    0px 1px 3px rgba(0, 0, 0, 0.1),
                    0px 1px 2px rgba(0, 0, 0, 0.06);
                border-radius: 12px;
                width: 100%;
            }
            &-title {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 700;
                font-size: 35px;
                line-height: 41px;
                /* identical to box height */

                /* #100820 */

                color: #100820;
            }
            &-createdby {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                /* identical to box height, or 133% */

                /* Default/gray/500 */

                color: #6b7280;
                white-space: pre-line;
            }
            &-subtitle {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 900;
                font-size: 18px;
                line-height: 21px;
                /* identical to box height */

                /* #100820 */

                color: #100820;
            }
            &-body {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                /* or 143% */

                color: #374151;
            }
            &-properties {
                flex-flow: row;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 0px;
                gap: 20px;
                flex-wrap: wrap;
            }
            &-property {
                flex-direction: column;
                align-items: flex-start;
                padding: 9px 13px;
                min-width: 117px;
                height: 43px;
                width: auto;
                background: #ffffff;
                border: 1px solid #d1d5db;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
                border-radius: 18px;
                flex: none;
                order: 0;
                flex-grow: 0;
                white-space: nowrap;
                max-width: 80%;
                overflow: hidden;
                &-title {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 15px;
                    /* identical to box height */

                    color: #000000;

                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
                &-subtitle {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 8px;
                    line-height: 10px;
                    /* identical to box height */

                    color: #818181;

                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }
            }
            &-link {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                /* identical to box height */

                /* #833CFC */

                color: #833cfc;
            }
            &-price {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 700;
                font-size: 35px;
                line-height: 41px;
                /* identical to box height */

                /* #100820 */

                color: #100820;
            }
            &-customprice {
                // min-width: 325px;
                max-width: 650px;
                width: 100%;

                border: 1px solid #d5d5d5;
                border-radius: 20px;

                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 36px;
                line-height: 44px;
                /* identical to box height */

                color: #000000;
                align-items: center;
                padding: 14px 20px;
            }
            &-created-detail {
                flex-flow: column;
                p {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    /* identical to box height */

                    color: #8a8a8a;
                }
                &-inner {
                    min-width: 182px;
                    width: auto;
                    height: 53px;
                    background: #ffffff;
                    box-shadow: 2px 2px 10px #f7f7f7;
                    border-radius: 30px;
                    align-items: center;
                    justify-content: center;
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    /* identical to box height */

                    overflow-wrap: anywhere;

                    color: #000000;
                }
            }
            &-bottom {
            }
        }
        &-connect {
            // position: fixed;
            width: 100%;
            height: auto;
            left: 0px;
            top: 0px;
            justify-content: center;
            align-items: center;
            background: transparent;
            margin: 22px 0px 12px 0;
            flex-flow: column;

            &-logo {
                margin-bottom: 12px;
                img {
                    width: 51.19px;
                    height: 59.11px;
                }
            }
            &-card {
                flex-flow: column;
                max-width: 744px;
                width: 100%;
                height: auto;

                background: #ffffff;
                box-shadow: 2px 2px 10px 10px #f7f7f7;
                border-radius: 20px;

                align-items: center;
                justify-content: center;
                gap: 12px;
                padding: 30px 30px;
                &-ico {
                    font-size: 62px;
                    color: black;
                    align-self: flex-start;
                }
                &-title {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 39px;
                    /* identical to box height */

                    color: #000000;
                    align-self: flex-start;
                }
                &-subtitle {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 20px;
                    /* identical to box height */

                    color: #000000;
                    align-self: flex-start;
                }
                &-button {
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 13px 25px;

                    max-width: 588px;
                    min-width: 325px;
                    width: 90%;
                    height: 48px;
                    background: #cbfb47;
                    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
                    border-radius: 14px;
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    /* identical to box height, or 100% */

                    /* #100820 */

                    color: #100820;
                    cursor: pointer;
                }
                &-input {
                    justify-content: space-between;
                    align-items: center;
                    flex-flow: row;
                    width: 100%;
                    height: 60px;
                    left: 75px;
                    top: 314px;
                    background: #f7f7f7;
                    border-radius: 14px;
                    padding: 12px 32px;
                    &-price {
                        font-family: "Montserrat";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 22px;
                        line-height: 39px;
                        /* identical to box height */

                        text-align: center;

                        color: #000000;
                    }
                    &-type {
                        font-family: "Montserrat";
                        font-style: normal;
                        font-weight: 700;
                        font-size: 22px;
                        line-height: 39px;
                        /* identical to box height */

                        text-align: center;

                        color: #000000;
                    }
                }
            }
        }
        &-buy {
            width: 100%;
            height: auto;
            left: 0px;
            top: 0px;
            justify-content: center;
            align-items: center;
            background: transparent;
            margin: 22px 0px 12px 0;
            flex-flow: column;

            &-logo {
                margin-bottom: 12px;
                img {
                    width: 51.19px;
                    height: 59.11px;
                }
            }
            &-card {
                flex-flow: column;
                width: 744px;
                height: auto;

                background: #ffffff;
                box-shadow: 2px 2px 10px 10px #f7f7f7;
                border-radius: 20px;

                align-items: center;
                justify-content: center;
                gap: 12px;
                padding: 30px 30px;
                &-ico {
                    font-size: 84.5px;
                    color: black;
                    align-self: flex-start;
                }
                &-title {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 39px;
                    /* identical to box height */

                    color: #000000;
                    align-self: flex-start;
                }
                &-subtitle {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 20px;
                    /* identical to box height */

                    color: #000000;
                    align-self: flex-start;
                }
                &-button {
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 13px 25px;

                    max-width: 588px;
                    min-width: 325px;
                    width: 90%;
                    height: 48px;
                    background: #cbfb47;
                    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
                    border-radius: 14px;
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
                    /* identical to box height, or 100% */

                    /* #100820 */

                    color: #100820;
                    cursor: pointer;
                }
                &-input {
                    justify-content: space-between;
                    align-items: center;
                    flex-flow: row;
                    max-width: 652px;
                    min-width: 325px;
                    width: 80%;
                    height: 108px;
                    left: 75px;
                    top: 314px;
                    background: #f7f7f7;
                    border-radius: 20px;
                    &-price {
                        font-family: "Montserrat";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 32px;
                        line-height: 39px;
                        /* identical to box height */

                        text-align: center;

                        color: #000000;
                    }
                    &-type {
                        font-family: "Montserrat";
                        font-style: normal;
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 39px;
                        /* identical to box height */

                        text-align: center;

                        color: #000000;
                    }
                }
            }
        }
        &-head {
            width: 100%;
            height: 800px;
        }
    }
    &-tab {
        &-container {
            flex-flow: column;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }
        &-title {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 29px;
            line-height: 35px;
            /* identical to box height */

            /* #100820 */

            color: #100820;
            margin-bottom: 12px;
            &-container {
                flex-flow: row;
                justify-content: space-between;
                width: 100%;
                &-menu {
                    flex-flow: column;
                }
            }
        }
        &-subtitle {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            /* gray/500 */

            color: #6b7280;
            margin-bottom: 12px;
        }
        &-table {
            flex-flow: column;

            width: 100%;
            &-filter {
                flex-flow: row;
                margin-left: -16px;
            }
            &-contain {
                flex-flow: column;
                table {
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 1px;

                    tr th {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        /* Purple */

                        color: #8d78fb;
                        height: 52px;
                    }

                    tr td {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        color: #374151;
                        border-top: 1px solid #e5e7eb;
                        height: 52px;
                    }
                }
            }
        }
    }
    &-dashboard {
        flex-flow: column;
        width: 100%;
        padding: 0px;
        gap: 20px;
        &-charts {
            flex: 1;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            gap: 15px;
            flex-wrap: wrap;
            &-card {
                flex-direction: column;
                align-items: center;
                padding: 30px 14px;
                gap: 14px;
                min-width: 243px;
                height: 395px;
                /* Default/white */

                background: #ffffff;
                /* /shadow/base */

                box-shadow:
                    0px 1px 3px rgba(0, 0, 0, 0.1),
                    0px 1px 2px rgba(0, 0, 0, 0.06);
                border-radius: 12px;

                &-content {
                    flex-flow: column;
                    width: 100%;
                    padding: 10px;
                    &-container {
                        border: 2.52083px dashed #b6a8f8;
                        box-shadow: inset 0px 0px 0px 4.58333px #ffffff;
                        border-radius: 11px;
                        width: 100%;
                        height: 200px;
                        overflow: hidden;
                    }
                }
                &-pie-content {
                    flex-flow: column;
                    width: 100%;
                    position: relative;
                    &-label {
                        position: absolute;
                        top: 105px;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 22px;
                        line-height: 89.19%;
                        /* identical to box height, or 27px */
                        white-space: nowrap;
                        text-align: center;

                        /* #595959 */

                        color: #595959;
                    }
                }
                &-title {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 25px;
                    line-height: 29px;
                    text-align: center;

                    /* #100820 */

                    color: #100820;
                }
                &-subtitle {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    text-align: center;

                    /* gray/500 */

                    color: #6b7280;
                }
                &-total {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 36px;
                    line-height: 89.19%;
                    /* or 32px */

                    text-align: center;

                    /* #595959 */

                    color: #595959;
                }
                &-subtotal {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 21px;
                    line-height: 89.19%;
                    /* identical to box height, or 19px */

                    color: #6b7280;
                }
                &-line {
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                }
                &.total-supply {
                    flex: 1;
                }
                &.claimend-supply {
                    flex: 1;
                }
                &.revenue {
                    flex: 2;
                }
                &.minting {
                    flex: 1.5;
                }
                &-mini {
                    flex-flow: row;
                    align-items: flex-start;
                    padding: 20px 0px 0px;
                    gap: 12px;

                    width: 100%;
                    height: 232px;
                    flex-wrap: wrap;
                    &-item {
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        padding: 10px 10px 10px 18px;
                        gap: 10px;

                        width: 100px;
                        height: 100px;

                        background: rgba(220, 211, 255, 0.6);
                        border-radius: 21px;
                        &-sub {
                            font-family: "Roboto";
                            font-style: normal;
                            font-weight: 600;
                            font-size: 30px;
                            line-height: 89.19%;
                            /* identical to box height, or 27px */

                            /* Purple */

                            color: #8d78fb;
                        }
                        &-label {
                            font-family: "Roboto";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 89.19%;
                            /* identical to box height, or 11px */

                            /* Purple */

                            color: #8d78fb;
                        }
                    }
                }
            }
        }
        &-top {
            flex-direction: column;
            align-items: flex-start;
            padding: 30px;
            gap: 30px;

            min-width: 100%;
            max-width: 100%;
            height: 448px;

            /* Default/white */

            background: #ffffff;
            /* /shadow/base */

            box-shadow:
                0px 1px 3px rgba(0, 0, 0, 0.1),
                0px 1px 2px rgba(0, 0, 0, 0.06);
            border-radius: 12px;
            &-head {
                flex-flow: row;
                align-items: center;
                padding: 0px;
                gap: 11px;

                width: 100%;
                height: 41px;
                justify-content: space-between;
                p {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 41px;
                    /* identical to box height */

                    /* #100820 */

                    color: #100820;
                }
            }
            &-body {
                flex-flow: row;
                justify-content: space-between;
                align-items: flex-start;
                padding: 0px;
                gap: 11px;

                min-width: 100%;
                height: 317px;
                width: auto;
                overflow-x: auto;
            }
        }
        &-latest {
            flex-flow: column;
            align-items: flex-start;
            padding: 30px;
            gap: 30px;

            width: 100%;
            height: 338px;

            /* Default/white */

            background: #ffffff;
            /* /shadow/base */

            box-shadow:
                0px 1px 3px rgba(0, 0, 0, 0.1),
                0px 1px 2px rgba(0, 0, 0, 0.06);
            border-radius: 12px;
            max-width: 100%;
            overflow-y: scroll;

            /* width */
            &::-webkit-scrollbar {
                width: 0px;
                height: 6px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px transparent;
                border-radius: 10px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #8d78fb;
                border-radius: 10px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #7762e1;
            }

            &-head {
                flex-flow: row;
                align-items: center;
                padding: 0px;
                gap: 11px;

                width: 100%;
                height: auto;
                justify-content: space-between;
                flex-wrap: wrap;
                p {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 35px;
                    line-height: 41px;
                    /* identical to box height */

                    /* #100820 */

                    color: #100820;
                }
            }
            &-table {
                width: 100%;
                table {
                    width: 100%;
                }
                table th  {
                    padding: 30px 12px;
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    /* #100820 */

                    color: #100820;
                    padding: 4px 12px;
                }
                table td {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    /* gray/500 */

                    color: #6b7280;
                    padding: 4px 12px;
                }
                .link {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    /* #833CFC */

                    color: #833cfc;
                    cursor: pointer;
                }
                .status {
                    span {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 2px 12px;

                        width: 98px;
                        height: 24px;

                        background: #dcd9e8;
                        border-radius: 4px;

                        /* Inside auto layout */

                        flex: none;
                        order: 0;
                        flex-grow: 0;
                        font-family: "Inter";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        text-align: center;

                        /* gray/800 */

                        color: #1f2937;
                    }
                }
            }
        }
    }
    &-ticketing {
        flex: 1;
        &-settings {
            &-form {
                flex-flow: row;
                &-column {
                    flex-flow: column;
                    flex: 1;
                }
            }
        }
        &.review {
            max-width: 808px;
        }
        &-review {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 30px;
            &-container {
                /* Auto layout */

                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 30px;
                gap: 70px;

                position: absolute;
                width: 808px;
                height: 696px;
                left: 20px;
                top: 388.5px;

                /* Default/white */

                background: #ffffff;
                /* /shadow/base */

                box-shadow:
                    0px 1px 3px rgba(0, 0, 0, 0.1),
                    0px 1px 2px rgba(0, 0, 0, 0.06);
                border-radius: 12px;
            }
            &-info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                flex: none;
                order: 0;
                flex-grow: 0;
                &-body {
                    flex-direction: row;
                    align-items: flex-end;
                    padding: 0px;
                    gap: 30px;
                    margin-top: 12px;
                }
                &-column {
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 30px;
                    flex: 1 0 auto;
                    width: 50%;
                    padding-left: 12px;
                }
                &-img {
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 0px;
                    p {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        /* #100820 */

                        color: #100820;

                        padding-left: 12px;
                    }
                }
                &-item {
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 4px;

                    width: 320px;
                    height: 44px;

                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    .title {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        /* #100820 */

                        color: #100820;
                    }
                    .subtitle {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        /* #595959 */

                        color: #595959;
                    }
                }
            }
            &-row {
                flex-flow: row;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding: 0px;
                gap: 24px;
                height: 62px;
                padding-left: 12px;
                &-item {
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 4px;

                    width: 320px;
                    height: 44px;

                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    flex-grow: 0;
                    .title {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        /* #100820 */

                        color: #100820;
                    }
                    .subtitle {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        /* #595959 */

                        color: #595959;
                        padding-left: 8px;
                    }
                }
            }
        }
        &-bouncer {
            &-trash {
                margin-top: 20px;
                font-size: 18px;
                color: #6b7280;
            }
        }
        &-video {
            display: none !important;
            border-radius: 25px;
            border: 1px solid #dcd3ff;
            background: #f8f5ff;

            /* shadow/sm */
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
            display: flex;
            flex-flow: row;
            padding: 9px 13px 9px 11px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: #8d78fb;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            &-icon {
                min-width: 25px;
            }
            span {
                max-width: 85%;
            }
        }
        &-submit {
            display: none !important;
            margin-top: 16px;
            width: 100%;
        }
    }
    &-reclaim {
        height: 100vh;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-flow: row;
        margin: auto;
        overflow-y: auto;
        &-rebackground {
            background-color: #ffffff;
            z-index: -99;
            height: 100%;
            position: fixed;
            width: 100vw;
            min-height: 100vh;
        }
        &-background {
            // filter: blur(8px);
            // -webkit-filter: blur(12px);
            filter: saturate(2) blur(64px) opacity(0.3);
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: fixed;
            z-index: -98;
            width: 100vw;
            min-height: 100vh;
            video {
                object-fit: fill;
            }
        }
        &-connect {
            position: absolute;
            top: 16px;
            right: 16px;
        }
        &-logo {
            position: fixed;
            bottom: 16px;
            right: 16px;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            img {
                max-width: 100%;
                height: auto;
                width: 100px;
            }
        }
        &-img {
            flex: 1;
            padding-left: 3rem;
            padding-right: 3rem;
            &.desktop {
                display: flex;
            }
            &.mobile {
                display: none;
            }
            &-space {
                padding-left: 3rem;
                padding-right: 3rem;
                width: 20%;
            }
            $shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
            $ring-offset-shadow: 0 0 #0000;
            $ring-shadow: 0 0 #0000;
            &-container {
                align-self: center;
                width: 100%;
                height: auto;
                margin-left: auto;
                margin-right: auto;
                &-object {
                    position: relative;
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                    border-radius: 12px;
                    overflow: hidden;
                    box-shadow: $ring-offset-shadow, $ring-shadow, $shadow;
                    img,
                    video {
                        width: 100%;
                        object-fit: contain;
                        z-index: 1;
                        transition: opacity 0.5s ease 0s;
                        opacity: 1;
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
        &-data {
            flex: 1;
            max-width: 50%;
            align-items: center;
            justify-content: center;
            &-contain {
                flex-flow: column;
                // background-color: #ffffff4d;
                padding: 48px;
                border-radius: 12px;
                width: 100%;
                align-items: flex-start;
                height: auto;
                gap: 1rem;
                // max-width: 36rem;
                margin: 20px;
                // border: 1px solid #9ca3af;
                color: black;
                &-row {
                    flex-flow: row;
                    width: 100%;
                    justify-content: flex-start;
                    &.end {
                        justify-content: flex-end;
                    }
                    &.buttons {
                        justify-content: space-between;
                        button {
                            white-space: nowrap;
                            width: auto;
                            flex: 1;
                            overflow: visible;
                            &:first-child {
                                margin-right: 12px;
                            }
                        }
                    }
                }
                &-column {
                    flex-flow: column;
                    align-items: flex-start;
                    gap: 12px;
                }
            }
            &-title {
                font-size: 25px;
                line-height: 32px;
                font-weight: 700;
                letter-spacing: 0.05rem;
                font-family:
                    -apple-system,
                    BlinkMacSystemFont,
                    Segoe UI,
                    Roboto,
                    Oxygen,
                    Ubuntu,
                    Cantarell,
                    Fira Sans,
                    Droid Sans,
                    Helvetica Neue,
                    sans-serif;
            }
            &-name {
                font-size: 28px;
                line-height: 40px;
                font-weight: 700;
                font-family:
                    -apple-system,
                    BlinkMacSystemFont,
                    Segoe UI,
                    Roboto,
                    Oxygen,
                    Ubuntu,
                    Cantarell,
                    Fira Sans,
                    Droid Sans,
                    Helvetica Neue,
                    sans-serif;
            }
            &-description {
                font-size: 16px;
                line-height: 23px;
                font-family:
                    -apple-system,
                    BlinkMacSystemFont,
                    Segoe UI,
                    Roboto,
                    Oxygen,
                    Ubuntu,
                    Cantarell,
                    Fira Sans,
                    Droid Sans,
                    Helvetica Neue,
                    sans-serif;
            }
            &-url {
                font-size: 15px;
                line-height: 23px;
                font-weight: 700;
                margin-top: -12px;
                font-family:
                    -apple-system,
                    BlinkMacSystemFont,
                    Segoe UI,
                    Roboto,
                    Oxygen,
                    Ubuntu,
                    Cantarell,
                    Fira Sans,
                    Droid Sans,
                    Helvetica Neue,
                    sans-serif;
            }
        }
    }
}
.team-members {
    flex-direction: column;
    align-items: flex-start;
    gap: 1px;
    align-self: stretch;
    &-table {
        width: 100%;
        padding: 12px;

        table {
            width: 100%;
        }
        thead {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #833cfc;
        }

        table th  {
            padding: 4px 0px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #833cfc;
        }
        table td {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            /* gray/500 */

            color: #6b7280;
            padding: 16px 0px;
            border-top: 1px solid #e5e7eb;
        }
        .link {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            /* #833CFC */

            color: #833cfc;
            cursor: pointer;
        }
        &-delete {
            cursor: pointer;
            color: red;
            font-size: 16px;
        }
        &-invite {
            cursor: pointer;
            color: green;
            font-size: 16px;
        }
        .status {
            span {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 2px 12px;

                width: 98px;
                height: 24px;

                background: #dcd9e8;
                border-radius: 4px;

                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 0;
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                text-align: center;

                /* gray/800 */

                color: #1f2937;
            }
        }
    }
}

.crossmint-custom {
    border-radius: 12px !important;
    overflow: hidden;
}

.twt,
[class*="twt"] {
    display: flex;
}
.twt {
    align-items: center;
    justify-content: center;

    &-container {
        flex-flow: column;
        width: 80%;
        max-width: 700px;
        min-width: 325px;
        &-body {
            flex-flow: column;
            justify-content: center;
            align-items: center;
            &-ico {
                width: 148px;
                height: 163px;
                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 0;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            &-title {
                font-family: "DM Sans";
                font-style: normal;
                font-weight: 700;
                font-size: 34px;
                line-height: 44px;

                color: #000000;

                /* Inside auto layout */

                flex: none;
                flex-grow: 0;
            }
            &-connect {
            }
        }
    }
    &-header {
        flex-flow: column;
        &-img {
            width: 100%;
            height: 200px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        &-row {
            flex-flow: row;
            justify-content: space-between;
            align-items: baseline;
            margin: 20px 0px;
            gap: 40px;
            &-cell {
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                grid-gap: 21px;
                gap: 21px;
                flex: none;
                /* order: 1; */
                flex-grow: 0;
                flex: 1;
            }
            .selected {
                color: #833cfc !important;
            }
            &-title {
                font-family: "DM Sans";
                font-style: normal;
                font-weight: 700;

                font-size: 26px;
                line-height: 24px;

                color: #000000;

                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 0;
            }
            &-subtitle {
                font-family: "DM Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
                /* identical to box height, or 233% */

                color: #000000;

                /* Inside auto layout */

                flex: none;
                order: 1;
                flex-grow: 0;
            }
        }
    }
    &-verify {
        /* Auto layout */

        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 57px;

        /* Inside auto layout */

        flex: none;
        order: 2;
        flex-grow: 0;
        margin-top: 12px;
        &-title {
            font-family: "DM Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 42px;
            /* identical to box height */

            color: #000000;
        }
        &-subtitle {
            font-family: "DM Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 31px;
            text-align: center;

            color: #000000;
        }
        &-from {
            font-family: "DM Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 31px;
            text-align: center;

            color: #8a8a8a;
        }
        &-copy {
            font-family: "DM Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 31px;
            text-align: center;

            color: #38b2e8;
            cursor: pointer;
        }
        &-connect {
            position: relative;
            .TwitterShareButton {
                position: absolute;
                iframe {
                    height: 50px !important;
                    width: 100% !important;
                    div,
                    a,
                    p,
                    span {
                        background: transparent !important;
                        color: transparent !important;
                        height: 50px !important;
                        width: 100% !important;
                    }
                }
            }
        }
    }
    &-mint {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 94px;
        margin-top: 6rem;
        &-body {
            flex-flow: row;
            width: 100%;

            max-width: 700px;
            min-width: 325px;

            gap: 60px;

            &-img {
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 101px;

                height: 386px;
                max-width: 50%;

                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 0;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: fill;
                }
            }
            &-contain {
                flex: none;
                order: 1;
                flex-grow: 0;
                flex-direction: column;
                align-items: flex-start;
                padding: 24px 0px;
                gap: 32px;
                max-width: 50%;
                &-top {
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 36px;
                    line-height: 20px;
                    /* identical to box height, or 56% */

                    color: #000000;
                }
                &-title {
                }
                &-strong {
                }
                &-cards {
                    flex-direction: row;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    gap: 16px;
                    &-item {
                        flex-direction: column;
                        align-items: flex-start;
                        padding: 9px 13px;

                        /* white */

                        background: #ffffff;
                        /* Default/gray/300 */

                        border: 1px solid #d1d5db;
                        /* shadow/sm */

                        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
                        border-radius: 18px;
                        &-title {
                            height: 15px;

                            font-family: "Montserrat";
                            font-style: normal;
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 15px;
                            /* identical to box height */

                            color: #000000;
                        }
                        &-subtitle {
                            height: 10px;

                            font-family: "Montserrat";
                            font-style: normal;
                            font-weight: 600;
                            font-size: 8px;
                            line-height: 10px;
                            /* identical to box height */

                            color: #818181;
                        }
                    }
                }
            }
        }
    }
    &-log {
        /* Auto layout */

        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 34px;
        margin-top: 7rem;
        &-title {
            font-family: "DM Sans";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 42px;
            /* identical to box height */

            color: #000000;
        }
        &-subtitle {
            font-family: "DM Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 31px;

            color: #000000;
        }
    }
}

.custom-footer {
    color: #9fa4dd;
    line-height: 25px;
    font-weight: 400;
    text-decoration: none !important;
    position: absolute;
    left: 48%;
    bottom: 2vw;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    width: 130px;
    background: rgba(0, 0, 0, 0.2705882353);
    padding: 1px 8px;
    border-radius: 12px;
    font-size: 10px;
    &.mini {
        font-size: 6px;
        right: 0px;
        width: 100px;
        transform: translate(-50%, -50%);
        left: 50%;
        bottom: 2rem;
        padding: 0px 3px;
    }
}
.custom-footer > a > img {
    position: fixed;
    padding-left: 8px;
    height: 15px;
    top: 6px;
}

.custom-footer > a {
    color: rgb(255, 255, 255);
    text-decoration: none;
}

.deploy,
[class*="deploy"] {
    display: flex;
}

.deploy {
    &-modal {
        flex-flow: column;
        justify-content: center;
        align-items: center;
        b {
            margin: 0px 4px;
        }
        &-title {
            // flex-flow: row;
            // font-family: "Montserrat";
            // font-style: normal;
            // font-weight: 700;
            // font-size: 40px;
            // line-height: 50px;
            // /* identical to box height */

            // color: #5a6275;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 31px;
            /* identical to box height, or 97% */

            text-align: center;

            /* Default/gray/700 */

            color: #374151;
        }
        &-subtitle {
            // flex-flow: row;
            // font-family: "Montserrat";
            // font-style: normal;
            // font-weight: 400;
            // font-size: 12px;
            // line-height: 22px;
            // text-align: center;

            // color: #000000;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            text-align: center;

            /* #595959 */

            color: #595959;

            margin-top: 12px;
            display: block;
            span {
                font-weight: 700;
                color: #833cfc;
            }
        }
        &-count {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 31px;
            /* or 129% */

            display: flex;
            align-items: center;
            text-align: center;

            /* #833CFC */

            color: #833cfc;
        }
        &-entries {
            flex-flow: row;
            height: 180px;
            margin-bottom: 16px;
            flex-wrap: nowrap;
            margin-top: 4px;
            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
            }

            &-container {
                padding: 0px 4px;
                gap: 0px;
                background: #f8f5ff;
                border: 1px solid #d1d5db;
                box-shadow:
                    inset 0px -6px 6px 1px rgba(0, 0, 0, 0.05),
                    inset 0px -2px 2px rgba(0, 0, 0, 0.05);
                border-radius: 12px;
                flex-flow: column;
                max-width: 100%;
                overflow-x: auto;
            }
        }
        &-footer {
            flex-flow: row;
            justify-content: center;
        }
        &-input {
            width: 80%;
            min-width: 320px;
            max-width: 700px;
            margin: 12px 0px;
        }
        &-token-container {
            height: 360px;
            align-items: center;
            justify-content: center;
            img {
                max-width: 100%;
                max-height: 100%;
            }
            &.ico {
                align-items: center;
                justify-content: center;
                color: #8d78fb;
                font-size: 12rem;
            }
        }
        &-success {
            align-items: center;
            justify-content: center;
            color: #10b981;
            font-size: 36px;
            margin-top: 18px;
        }
    }
}

.general-title {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 51px;
    color: #5a6275;
    margin-bottom: 2rem;
}
.uppercase {
    text-transform: uppercase;
}

.btn_loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.bottom_clear {
    height: 75px;
}

.input {
    &-label {
        &_monserrat {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height */

            color: #495057;
        }
        &-mini {
            font-family: "Montserrat";
            font-style: normal;
            font-size: 11px;
            line-height: 20px;
            font-weight: 500;
            /* identical to box height */
            color: #555;
            margin-left: 4px;
        }
        &-primary {
            color: #6042d6 !important;
        }
    }
    &-text-area {
        min-height: 7em;
    }
    &-transparent {
        color: #fff;
        background: transparent;
        // -webkit-appearance: none;
        border: transparent;

        box-shadow: none;
        & option {
            background-color: transparent;
        }
        &:focus {
            color: #fff;
            background: transparent;
            // -webkit-appearance: none;
            border: transparent;

            box-shadow: none;
        }
    }
    &-container {
        display: flex;
        flex-flow: row;
        margin-bottom: 12px;
        &_full {
            width: 100%;
            margin-right: 6px;
            padding-right: 12px;
        }
        &_lg {
            width: 50%;
            margin-right: 6px;
            padding-right: 12px;
        }
        &_sm {
            width: 25%;
            margin-right: 6px;
            padding-right: 12px;
        }
        &_icon {
            align-items: center;
            width: 20px;
            justify-content: center;
            display: flex;
            font-size: 14px;
            margin-left: 12px;
            cursor: pointer;
        }
    }
    &-error {
        border: red solid 1px !important;
        color: red;
    }
}

.c-label {
    &-primary {
        color: #6042d6;
    }
}
.form-actions {
    &-row {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        button {
            margin: 0 0 0 8px !important;
        }
    }
}

.custom-row {
    flex-flow: row;
    display: flex !important;
}
.button {
    &_red {
        background: red;
        border: none;
    }
    &_gray {
        background: #74788d;
        border: none;
    }
    &_full {
        width: 100%;
    }
    &_white {
        color: #74788d;
        background: white;
        border: 1px solid #74788d !important;
    }
    &_none {
        color: black;
        background: transparent;
        border: none !important;
    }
    &-br4 {
        border-radius: 4px;
    }
    &-nbr {
        border-radius: 0px;
    }
}

select.minimal {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    background-image: linear-gradient(45deg, transparent 50%, #ced4da 50%),
        linear-gradient(135deg, #ced4da 50%, transparent 50%);
    background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        calc(100% - 2.5em) 0.5em;
    background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
    background-repeat: no-repeat;
}

select.minimal:focus {
    background-image: linear-gradient(45deg, green 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, green 50%), linear-gradient(to right, #ccc, #ccc);
    background-position:
        calc(100% - 15px) 1em,
        calc(100% - 20px) 1em,
        calc(100% - 2.5em) 0.5em;
    background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
    background-repeat: no-repeat;
    border-color: green;
    outline: 0;
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

.mt-required-error {
    border: red solid 1px !important;
    color: red;
}

.gray-scale {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
}

/* 
custom switch*/
.scroll-track {
    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px transparent;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #8d78fb;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #7762e1;
    }
}
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    &.small {
        transform: scale(50%);
        transform: scale(50%, 50%);
        margin: 0px -10px;
    }
    &.medium {
        width: 50px;
        height: 20px;
    }
}
.toggle-switch input[type="checkbox"] {
    display: none;
}
.toggle-switch .switch {
    position: absolute;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 25px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
    position: absolute;
    content: "";
    left: 2px;
    top: 2px;
    width: 21px;
    height: 21px;
    background-color: #aaa;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.toggle-switch.medium .switch::before {
    position: absolute;
    content: "";
    left: 6px;
    top: 2px;
    width: 16px;
    height: 16px;
    background-color: #aaa;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.toggle-switch input[type="checkbox"]:checked + .switch::before {
    transform: translateX(25px);
    background-color: #fff;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
    background-color: #556ee6;
}
.ReactModal__Overlay {
    z-index: 1040;
}

.no-max-width {
    max-width: none !important;
}
/* Spinner  */

.min-loader {
    align-items: initial;
    transform: scale(0.7);
    height: 32px;
    margin-top: -10px;
}

@keyframes ldio-tw {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
.ldio-tw div {
    box-sizing: border-box !important;
}
.ldio-tw > div {
    position: absolute;
    width: 75px;
    height: 75px;
    top: 12.5px;
    left: 12.5px;
    border-radius: 50%;
    border: 3px solid #000;
    border-color: #ffffff transparent #ffffff transparent;
    animation: ldio-tw 0.36101083032490977s linear infinite;
}

.ldio-tw.black > div {
    position: absolute;
    width: 75px;
    height: 75px;
    top: 12.5px;
    left: 12.5px;
    border-radius: 50%;
    border: 3px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: ldio-tw 0.36101083032490977s linear infinite;
}
.ldio-tw > div:nth-child(2) {
    border-color: transparent;
}
.ldio-tw > div:nth-child(2) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
}
.ldio-tw > div:nth-child(2) div:before,
.ldio-tw > div:nth-child(2) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 3px;
    height: 3px;
    top: -3px;
    left: 33px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 72px 0 0 #ffffff;
}
.ldio-tw > div:nth-child(2) div:after {
    left: -3px;
    top: 33px;
    box-shadow: 72px 0 0 0 #ffffff;
}
.loadingio-spinner-dual-ring-e {
    width: 51px;
    height: 51px;
    display: inline-block;
    overflow: hidden;
    background: none;
    margin-top: -2px;
}
.ldio-tw {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.51);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}
.ldio-tw div {
    box-sizing: content-box;
}

.button-menu-dots {
    padding: 0px 0px 3px 13px !important;
}

.card-picture-img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.modal-size {
    width: 179.33px !important;
    height: 229.76px !important;
}

.backStart {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    display: none;
}
.counter {
    display: flex;
    flex: 1;
    flex-flow: row;
    padding: 12px;
    border-radius: 12px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgb(156 163 175 / 1);
    &-minus {
        font-size: 16px;
        color: #4e4c4c;
        height: 100%;
        cursor: pointer;
        padding: 4px;
        width: 20px;
    }
    &-plus {
        font-size: 16px;
        color: #4e4c4c;
        height: 100%;
        cursor: pointer;
        padding: 4px;
        width: 20px;
    }
    &-number {
        font-size: 14px;
        color: black;
    }
}
@media screen and (max-width: 1600px) {
    .theme-token-card {
        width: calc(90% / 4) !important;
        height: auto !important;
        max-height: none !important;

        img.fixed {
            height: auto !important;
            width: 100%;
            object-fit: cover;
            max-height: 240px !important;
        }
        .card-picture.generative {
            height: 240px !important;
            .card-picture-empty {
                height: 240px !important;
            }
        }
        img.generative {
            height: 240px !important;
            width: 100%;
            object-fit: cover;
            max-height: 240px !important;
        }
    }
}
@media screen and (max-width: 1282px) {
    .theme-token-card {
        width: calc(90% / 3) !important;
        height: auto !important;
        max-height: none !important;

        img.fixed {
            height: auto !important;
            width: 100%;
            object-fit: cover;
            max-height: 250px !important;
        }
        .card-picture.generative {
            height: 250px !important;
            .card-picture-empty {
                height: 250px !important;
            }
        }
        img.generative {
            height: 250px !important;
            width: 100%;
            object-fit: cover;
            max-height: 250px !important;
        }
    }
}
@media screen and (max-width: 1068px) {
    .theme-token-card {
        width: calc(90% / 2) !important;
        height: auto !important;
        max-height: none !important;
        img.fixed {
            height: auto !important;
            width: 100%;
            object-fit: cover;
            max-height: 260px !important;
        }
        .card-picture.generative {
            height: 260px !important;
            .card-picture-empty {
                height: 260px !important;
            }
        }
        img.generative {
            height: 260px !important;
            width: 100%;
            object-fit: cover;
            max-height: 260px !important;
        }
    }
}
@media screen and (max-width: 826px) {
    .theme-token-card {
        width: calc(90% / 1) !important;
        height: auto !important;
        max-height: none !important;
        img.fixed {
            height: auto !important;
            width: 100%;
            object-fit: cover;
            max-height: 316px !important;
        }
        .card-picture.generative {
            height: 316px !important;
            .card-picture-empty {
                height: 316px !important;
            }
        }
        img.generative {
            height: 316px !important;
            width: 100%;
            object-fit: cover;
            max-height: 316px !important;
        }
    }
    .collections-choose-type {
        &-mobile {
            display: flex;
        }
        &-desktop {
            display: none;
        }
    }
    .collections-reclaim {
        flex-flow: column;
        height: auto;
        padding: 50px 0;
        &-data {
            max-width: calc(100% - 10px);
            &-contain {
                margin: 5px;
                padding: 30px;
                &-column {
                    width: 100%;
                }
                &-row {
                    flex-flow: column;
                    gap: 1rem;
                    width: 100%;
                    &.buttons {
                        button {
                            margin: 0 !important;
                        }
                    }
                }
            }
            &-title {
                font-size: 23px;
                letter-spacing: 0.01rem;
            }
            &-name {
                font-size: 26px;
                line-height: 34px;
            }
            &-description {
                font-size: 14px;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
            }
            &-url {
                font-size: 13px;
                overflow-wrap: break-word;
                display: block;
            }
        }
        &-img {
            &.desktop {
                display: none !important;
            }
            &.mobile {
                display: flex;
                padding: 0px;
                max-width: 90%;
            }
            &-space {
                display: none;
            }
        }
    }
}
@media screen and (max-width: 500px) {
    .twt {
        &-header {
            .twt-header-row-title {
                display: none;
            }
            .twt-header-row-subtitle {
                display: none;
            }
            .twt-header-row-title.selected {
                display: flex;
                br {
                    display: none;
                }
            }
            .twt-header-row-cell {
                display: none;
            }
            .twt-header-row-cell.selected {
                display: flex;
            }
        }
        &-mint-body {
            flex-flow: column;
            &-img {
                max-width: 100%;
            }
            &-contain {
                max-width: 100%;
            }
        }
    }
    .backStart {
        display: flex;
    }
    .forceFlex {
        display: flex !important;
    }
    .rowcard-body {
        margin-left: 2px;
    }
    .container-deploy {
        padding: 30px 15px;
    }
    .m-center {
        display: flex;
        justify-content: center;
    }
    .global-dialog {
        max-width: 100%;
        min-width: auto;
    }
    .jsmodal-dialog {
        min-width: auto !important;
    }
    .container-create-all {
        .container-video-all {
            display: none;
        }
        .collections-create-form-row {
            flex-flow: column;
            &-img {
                order: 2;
                align-items: center;
            }
            &-ico {
                right: 3rem;
            }
        }
        .collections-create-form-upload-container-dropzone {
            order: 2;
            margin-top: 16px;
        }
    }
    .collections-claim {
        flex-flow: column;
        align-items: center;
        justify-content: center;
        &-img {
            max-width: 100%;
            width: 100%;
        }
        &-detail {
            max-width: 100%;
            width: 100%;
        }
    }
    .collections-settings-tab {
        &-title {
            padding-left: 0px;
        }
        &-subtitle {
            padding-left: 0px;
        }
    }
    .collections-ticketing {
        &-settings-form {
            flex-flow: column;
            padding: 30px 0px;
            gap: 12px;
            &-column {
                align-items: center;
                &.img {
                    order: 0;
                    .collections-create-form-row-img {
                        align-items: flex-start !important;
                    }
                    .collections-create-form-row-ico {
                        right: 4rem;
                    }
                    .card-picture {
                        align-self: center;
                    }
                }
                &.input {
                    order: 1;
                }
            }
        }
        .container-settings-tab-side {
            display: none;
        }
        &-video {
            display: flex !important;
        }
        &-submit {
            display: flex !important;
        }
        &-not-submit {
            display: none !important;
        }
        &-review {
            &-info {
                &-body {
                    flex-flow: column;
                    align-items: center;
                }
                &-column {
                    width: 100%;
                }
            }
            &-row {
                flex-flow: column;
                height: auto;
            }
        }
        &-bouncer-trash {
            justify-content: center;
            margin-top: 0px;
            margin-bottom: 12px;
            &-add  {
                width: 100%;
            }
        }
    }
    .collections-dashboard {
        &-charts {
            &-card-mini {
                justify-content: center;
                height: auto;
            }
            &-card {
                height: auto;
                width: 100%;
            }
        }
    }
    .collections-settings-header-button {
        &.oneandone {
            display: none;
        }
    }

    .container-create-all {
        &.quickmint {
            flex-flow: column;
            .container-settings-tab-side {
                display: none !important;
            }
        }
        &.oneandone {
            flex-flow: column;
            .container-video-all {
                display: none !important;
            }
        }
        .ordinals {
            flex-flow: column;
        }
    }
    .container-settings-tab {
        &.oneandone {
            flex-flow: column;
            .container-settings-tab-side {
                display: none !important;
            }
        }
    }
    .input-container {
        flex-flow: column;
        gap: 12px;
        &_lg {
            width: 100%;
        }
        &_sm {
            width: 100%;
        }
        &_icon {
            width: 100%;
            font-size: 20px;
        }
    }
    .collections-minting {
        &-top {
            &-form {
                flex-flow: column;
                .collections-create-form-row-img {
                    & > div {
                        height: auto;
                    }
                    img {
                        height: auto;
                    }
                }
            }
            &-group {
                margin-top: 24px;
            }
            &-display {
                max-width: 100%;
            }
        }
    }
    .collections-ordinals {
        &.transaction {
            max-width: 100%;
        }
        &-row {
            flex-flow: column;
            width: 200px;
        }
        &-box {
            width: 100%;
        }
        &-inscription {
            width: 100%;
            align-items: center;
        }
        &-transactions {
            max-width: 100%;
            overflow: auto;
            &-box {
                width: 178px;
                min-width: 178px;
                margin-bottom: 20px;
            }
        }
        &-view-contain {
            flex-flow: column;
            &-body {
                max-width: none;
                width: 100%;
                &-rows {
                    &-text {
                        word-break: break-all;
                    }
                }
                &-detail {
                    flex-flow: column;
                    .mb-6 {
                        margin-bottom: 2rem;
                    }
                    .mt-12 {
                        margin-top: 4rem;
                    }
                    .mb-12 {
                        margin-bottom: 4rem;
                    }
                    &-status {
                        display: flex;
                        flex-flow: row;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
    .collections-layers {
        &-container {
            flex-flow: column;
        }
        &-ltable {
            width: 100%;
        }
        &-preview {
            width: 100%;
        }
        &-row {
            width: 100%;
        }
        &-rules-contain-row {
            flex-flow: column;
            .form-group {
                margin: 4px 0px;
            }
        }
        &-dialog-table {
            overflow-x: auto;
            table {
                min-width: 400px;
            }
        }
    }
    .modalcard-legendary {
        &-contain {
            flex-flow: column;
            &-img {
                max-width: fit-content;
            }
        }
        &-input-row {
            flex-flow: column;
        }
    }
}
@media screen and (max-height: 2560px) {
    .collections-token-clearfix {
        height: 1000px;
    }
}
@media screen and (max-height: 3840px) {
    .collections-token-clearfix {
        height: 50vh;
    }
}
