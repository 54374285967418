// Variables

//
// custom-variables
//

$primary: #a066ff;
$secundary: #833cfc;
$background-violet: #5831c5;
$light-purple: #b6a8f8;

$background: #f8f5fe;
$notification: #fee2e2;

// Labels
$label-bottom-color: #595959;
$label-header-color: #e9ecef;

//buttons backgrounds
$update: #cbfb47;
