.page {
    .page-header {
        background: transparent;
        border-bottom: none;
        position: relative;
        padding: 0;

        @media screen and (max-width: 600px) {
            padding: 8px 16px;
        }

        .page-title {
            margin-top: 0;
            font-size: 32px;
            font-weight: 500;
            margin-bottom: 24px;
        }

        .breadcrumb {
            background: none;
            padding: 0 0 24px 0;
        }

        .page-header-actions {
            position: absolute;
            margin: auto;
            right: 30px;
            top: 50%;

            -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                 -o-transform: translateY(-50%);
                    transform: translateY(-50%);

            @media only screen and (max-width : 800px) {
                position: relative;
                display: block;
                margin: auto;
                width: auto;
                right: 0;
                top: 0;
            }

            .btn-icon {margin-left: 6px}

            * {
                margin-bottom: 0;
            }
        }
    }

    .dropdown {
        .dropdown-menu {
            a {color: $fourth}
        }
    }
}

.search-box{
    .form-control{
        border-radius: 15px;
        padding-left: 32px !important;
    }

    .search-icon{
        font-size: 16px;    
        position: absolute;
        left: 24px;
        top: 0;
        line-height: 38px;
    }
}