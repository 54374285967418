//
// _buttons.scss
//
button,
a {
  outline: none !important;
}

// Rounded Buttons
.btn {
  padding: 12px 18px;
}

.btn-rounded {
  border-radius: 8px;
}

.btn-outline {
  border: 1px solid #c6c6c6;
  background: white;
  color: #c6c6c6;
}

.btn-outline:hover,
.btn-outline:active,
.btn-outline:visited {
  border: 1px solid #a8c9fc !important;
  background: white !important;
  color: #a8c9fc !important;
}

.btn-secondary {
  border: 1px solid #c6c6c6;
  background: white;
  color: #c6c6c6;
}

.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:visited {
  border: 1px solid #a8c9fc !important;
  background: white !important;
  color: #a8c9fc !important;
}

.btn-outline-light {
  color: $gray-900;
}

.btn-sm {
  padding: 4px 8px;
}
