#toast-container > .toast {
    background-image: none !important;
    border-radius: 6px;
    border: none;
    font-size: 14px !important;
    border: none !important;
    opacity: 1 !important;
    box-shadow: none;
}

#toast-container > div {
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    filter: alpha(opacity=100);
    border: none;
}
.toast {
    opacity: 1 !important;
}

#toast-container > .toast:before {
    position: fixed;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 20px !important;
    line-height: 18px;
    float: left;
    color: #fff;
    padding-right: 0.5em;
    margin: auto 0.5em auto -1.5em;
}
#toast-container > .toast-warning:before {
    font-size: 14px;
    line-height: 20px;
    color: #b45309;
    content: "\f071";
}
#toast-container > .toast-error:before {
    font-size: 14px;
    line-height: 20px;
    color: #b91c1c;
    content: "\f071";
}
#toast-container > .toast-info:before {
    font-size: 14px;
    line-height: 20px;
    color: #1d4ed8;
    content: "\f05a";
}
#toast-container > .toast-success::before {
    font-size: 14px;
    line-height: 20px;
    color: #047857;
    content: "\f058";
}
#toast-container > .toast-title {
    font-weight: 500 !important;
    font-size: 16px !important;
}
#toast-container > .toast-warning {
    background: #fef3c7;
    background-color: #fef3c7!important;
    color: #b45309;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
#toast-container > .toast-error {
    background: #fee2e2;
    background-color: #fee2e2!important;
    color: #b91c1c;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
#toast-container > .toast-info {
    background: #dbeafe;
    background-color: #dbeafe!important;
    color: #1d4ed8;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
#toast-container > .toast-success {
    background: #d1fae5;
    background-color: #d1fae5!important;
    color: #047857;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    opacity: 1 !important;
}
