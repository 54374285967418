//
// breadcrumb.scss
//

// Breadcrumb item arrow
.breadcrumb {
    margin: 0px 0px 20px 0px !important;
    p {
        margin: 0;
    }
    &-row {
        display: flex;
        flex-flow: row;
        align-items: center;
    }
}
.breadcrumb-item {
    > a {
        // color: $gray-700;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 143% */

        /* #595959 */

        color: #9ca3af;
    }
    &.active {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 143% */

        /* #595959 */

        color: #595959;
        margin: 0 16px;
    }
    + .breadcrumb-item {
        &::before {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 143% */

            /* #595959 */

            color: #9ca3af;
            // font-family: "Material Design Icons";
            // content: ">";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f054";

            margin-right: 16px;
        }
    }
}
