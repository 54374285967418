.sidebar {
    background: $primary;
    text-align: left;
    position: fixed;
    z-index: 500;
    border: none;
    width: 100%;
    margin: 0;
    height: 60px;
    top: 30px;
    &.open {
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
            display: block;
        }

        @media only screen and (max-device-width: 1024px) {
            display: block;
        }
    }

    .site-menubar {
        .site-menubar-header {
            display: flex;
            width: 15%;
            // padding: 24px 0px 24px 24px;
            flex: 1;
            img {
                max-width: 150px;
            }
        }

        .site-menu-project {
            flex: 0;
            width: 15%;
            height: 20px;
            display: inline-block !important;
            position: relative;
        }
        .centered {
            width: 95%;
            margin: 0 auto;
        }
        &.flex {
            display: flex;
            flex-flow: row;
            min-width: 95%;
            justify-content: space-between;
            align-items: center;

            @media screen and (min-width: 1500px) {
                // max-width: 1425px;
                // width: 1425px;
                min-width: 1425px;
                margin-left: 50px;
            }
        }

        .navbar-profile {
            display: inline-block;
            vertical-align: middle;
            text-align: right;
            width: 65%;

            .navbar-profile-actions {
                vertical-align: top;
                width: 50%;

                .header-item-profile-name {
                    color: white;
                }
                .header-item-chevron {
                    color: white;
                }
            }
        }
    }
}

@media only screen and (max-width: 360px) {
    .sidebar {
        height: 120px;
    }
    .site-menubar-header {
        flex-flow: column;
    }
}
