.order-action-bar {
    width: 8.5in;
    margin: 0 auto;
    padding: 24px 0;
    text-align: center;
}

.order-footer-bar {
    width: 8.5in;
    margin: 0 auto;
    padding: 24px 0;
    text-align: center;
}

.order {
    box-sizing: border-box; padding: 0.35in; text-align: center;
    margin: 0 auto; min-height: 11in;
    background: #FFF; border: 1px; width: 8.5in; box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);

    .datosempresa { padding: 0.15in 0.04in; }
    .notaremision img {width: 60%; max-width: 60%;}
    .folio { text-align: left;padding: 0.15in 0.04in; }
    .items { padding: 0.15in 0.04in; margin: 0.2in 0 0.2in 0 }
    .order-header {
        padding-bottom: 24px;
        margin-bottom: 24px;
        border-bottom: #f4f4f4 0.5px solid;

        h1 {font-size: 1em}
    }

    .title {
        padding: 16px;
        color: white;
        font-size: 16px;
        text-align: center;
        margin-bottom: 24px;
        background: #424242;
        text-transform: uppercase;
    }

    table { font-size: 85%; table-layout: liquid; width: 100%; border: 0 }
    table { border-collapse: collapse; border-spacing: 2px; }
    th, td { border-width: 0; padding: 0.5em; position: relative; text-align: left; }
    th, td { border-style: solid; }
    th { background: #EEE; border-color: #f1f1f1; }
    td { border-color: #DDD; }
    thead {font-weight: bold;}

    .totales { text-align: right; padding: 16px 10px; }
    .totales .cantidadLetra { text-align: center;padding: 0; }
    .totales .totalItems { text-align:center;padding:0; font-weight: bold}
    .paymentType {text-align: left; padding: 3%;}
}

@media print {
    * { -webkit-print-color-adjust: exact; }
    html { background: none; padding: 0; }
    body { box-shadow: none; margin: 0; }
    .page { box-shadow: none; }
    span:empty { display: none; }
    .order-action-bar, .order-footer-bar {display: none}
}