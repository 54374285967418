@import "variables";
@import "toastr";

// Inputs
.input {
    &-primary {
        &-input {
            /* Text input */
            box-sizing: border-box;
            /* Auto layout */
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 9px 13px;
            gap: 8px;
            /* white */
            background: $primary;
            color: white;
            /* Default/gray/300 */
            border: 1px solid #d1d5db;
            /* shadow/sm */
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
            /* Inside auto layout */
            flex: none;
            order: 1;
            align-self: stretch;
            height: 39px;
            &.circle {
                border-radius: 18px;
            }
        }
    }
    &-secundary {
        &-dropdown {
            /* Dropdown/Trigger */

            box-sizing: border-box;

            /* Auto layout */

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 9px 15px;
            gap: 8px;

            // width: 196px;
            height: 42px;

            /* #A066FF */

            background: #a066ff;
            /* #A066FF */

            border: 1px solid #a066ff;
            /* shadow/sm */

            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);

            // Inner font
            /* text-sm/leading-5/font-medium */

            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            /* White */

            color: #ffffff;

            &.circle {
                border-radius: 18px;
            }
        }
    }
    &-white {
        &-input {
            /* Text input */
            box-sizing: border-box;
            /* Auto layout */
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 9px 13px;
            gap: 8px;
            /* white */
            background: #ffffff;
            /* Default/gray/300 */
            border: 1px solid #d1d5db;
            /* shadow/sm */
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
            /* Inside auto layout */
            flex: none;
            order: 1;
            align-self: stretch;
            height: 39px;
            &.circle {
                border-radius: 18px;
            }
            &.small {
                height: 26px;
                padding: 4px 8px;
            }
            &:focus {
                border: 1px solid $secundary;
            }
            &::placeholder {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                /* Default/gray/500 */

                color: #6b7280;
            }
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            /* Default/gray/500 */

            color: #6b7280;
        }
        &-select {
            box-sizing: border-box;

            /* Auto layout */

            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 13px;

            height: 34px;

            /* white */

            background: #ffffff;
            /* Default/gray/300 */

            border: 1.00591px solid #d1d5db;
            /* shadow/sm */

            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);

            /* Inside auto layout */
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04LjIzMzU2IDAuMjg0NzY2TDUuMDAwMjMgMy41MTgxTDEuNzY2ODkgMC4yODQ3NjZDMS40NDE4OSAtMC4wNDAyMzQ0IDAuOTE2ODk0IC0wLjA0MDIzNDQgMC41OTE4OTUgMC4yODQ3NjZDMC4yNjY4OTUgMC42MDk3NjYgMC4yNjY4OTUgMS4xMzQ3NyAwLjU5MTg5NSAxLjQ1OTc3TDQuNDE2ODkgNS4yODQ3N0M0Ljc0MTg5IDUuNjA5NzcgNS4yNjY4OSA1LjYwOTc3IDUuNTkxODkgNS4yODQ3N0w5LjQxNjg5IDEuNDU5NzdDOS43NDE4OSAxLjEzNDc3IDkuNzQxODkgMC42MDk3NjYgOS40MTY4OSAwLjI4NDc2NkM5LjA5MTg5IC0wLjAzMTkwMSA4LjU1ODU2IC0wLjA0MDIzNDQgOC4yMzM1NiAwLjI4NDc2NloiIGZpbGw9IiM2QjcyODAiLz4KPC9zdmc+Cg==);
            background-repeat: no-repeat, repeat;
            background-position: right 1.5rem center;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-size: 12px 14px;
            height: 39px;
            &.circle {
                border-radius: 18.1064px;
            }
            &:focus {
                border: 1px solid $secundary;
                box-shadow: none !important;
            }
            &:active {
                border: 1px solid $secundary;
            }
        }
        &-buttongroup {
            background: white;
            color: #d1d5db;
            border-radius: 18px;
            overflow: hidden;
            height: 39px;
            button {
                height: 39px;
                border-radius: 5px;
                border: 1px solid #b6a8f8;
                /* shadow/sm */
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
                overflow: hidden;
                display: flex;
                align-items: center;
                &.selected {
                    color: white;
                    background-color: $secundary;
                }
                &:hover {
                    border: 1px solid $primary !important;
                    color: white !important;
                    background-color: $primary !important;
                }
            }

            button:first-child {
                border-radius: 18px 5px 5px 18px;
            }
            button:last-child {
                border-radius: 5px 18px 18px 5px;
            }
        }
    }
    &-drop {
        &-white {
            width: 100%;
            height: 38px;
            /* white */
            background: #ffffff !important;
            /* Default/gray/300 */
            border: 1px solid #d1d5db !important;
            /* shadow/sm */
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
            border-radius: 18px;

            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            padding: 0px 14px 0px 14px;
            /* identical to box height, or 143% */

            /* Default/gray/500 */

            color: #6b7280;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .header-item-project-name {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                /* Default/gray/500 */

                color: #6b7280;
            }
            &-item {
                width: 100%;
                .dropdown-item {
                    &.red {
                        color: #f87171;
                    }
                    &.green {
                        color: #03b071;
                    }
                }
            }
            .dropdown-menu.dropdown-menu-right {
                width: 100%;
            }
        }
        &-radio {
            width: 100%;
            height: 38px;
            /* white */
            background: #ffffff !important;
            /* Default/gray/300 */
            border: 1px solid #d1d5db !important;
            /* shadow/sm */
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
            border-radius: 18px;

            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            padding: 0px 14px 0px 14px;
            /* identical to box height, or 143% */

            /* Default/gray/500 */

            color: #6b7280;
            display: flex;
            align-items: center;
            justify-content: space-between;
            overflow: hidden;
            &-item {
                width: 100%;
            }
            .dropdown-menu.dropdown-menu-right {
                width: 100%;
            }
            .input-group .form-control {
                box-shadow: none;
            }
            .input-group {
                height: 100%;
                box-shadow: none;
                &-append {
                    height: 100%;
                    button {
                        border: none;
                        height: 100%;
                        padding: 4px;
                    }
                }
            }
            input {
                border: none;
            }
            &-img {
                width: 20px;
                height: 100%;
                align-items: center;
                justify-content: center;
                display: flex;
                margin-right: 4px;
                img {
                    width: 100%;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }
            &-menu {
                input[type="radio"] {
                    margin-top: 3px;
                    -webkit-appearance: none;
                    appearance: none;
                    background-color: #fff;
                    /* margin: 0; */
                    font: inherit;
                    color: currentColor;
                    width: 12px;
                    height: 12px;
                    border: 1px solid currentColor;
                    border-radius: 50%;
                }

                input[type="radio"]::before {
                    content: "";
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    transform: scale(0);
                    transition: 120ms transform ease-in-out;
                    box-shadow: inset 1em 1em $secundary;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 1px;
                    margin-left: 1px;
                }

                input[type="radio"]:checked::before {
                    transform: scale(1);
                }

                .dropdown-item {
                    span {
                        margin-left: 18px;
                    }
                }
                .checkbox-item {
                    // background-color: $secundary;
                    // accent-color: $secundary;
                    width: 12px;
                    height: 12px;
                    background-color: white;
                    border-radius: 50%;
                    vertical-align: middle;
                    border: 1px solid currentColor;
                    appearance: none;
                    -webkit-appearance: none;
                    outline: none;
                    cursor: pointer;
                }
                .checkbox-item:checked {
                    background-color: $secundary;
                    accent-color: $secundary;
                    box-shadow: inset 1em 1em $secundary;
                }
            }
        }
        &-green {
            width: 100%;
            height: 38px;
            /* white */
            background: #ffffff !important;
            /* Default/gray/300 */
            border: 1px solid #03b071 !important;
            /* shadow/sm */
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
            border-radius: 18px;

            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            padding: 0px 14px 0px 14px;
            /* identical to box height, or 143% */

            /* Default/gray/500 */

            color: #03b071 !important;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &-item {
                width: 100%;
                .dropdown-item {
                    &.red {
                        color: #f87171;
                    }
                    &.green {
                        color: #03b071;
                    }
                }
            }
            .dropdown-menu.dropdown-menu-right {
                width: 100%;
            }
        }
        &-red {
            width: 100%;
            height: 38px;
            /* white */
            background: #ffffff !important;
            /* Default/gray/300 */
            border: 1px solid #f87171 !important;
            /* shadow/sm */
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
            border-radius: 18px;

            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            padding: 0px 14px 0px 14px;
            /* identical to box height, or 143% */

            /* Default/gray/500 */

            color: #f87171 !important;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &-item {
                width: 100%;
                .dropdown-item {
                    &.red {
                        color: #f87171;
                    }
                    &.green {
                        color: #03b071;
                    }
                }
            }
            .dropdown-menu.dropdown-menu-right {
                width: 100%;
            }
        }
    }
    &-green {
        &-select {
            box-sizing: border-box;

            /* Auto layout */

            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 13px;

            height: 34px;

            /* white */

            background: #ffffff;
            /* Default/gray/300 */

            border: 1.00591px solid #03b071;
            color: #03b071;
            /* shadow/sm */

            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);

            /* Inside auto layout */
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04LjIzMzU2IDAuMjg0NzY2TDUuMDAwMjMgMy41MTgxTDEuNzY2ODkgMC4yODQ3NjZDMS40NDE4OSAtMC4wNDAyMzQ0IDAuOTE2ODk0IC0wLjA0MDIzNDQgMC41OTE4OTUgMC4yODQ3NjZDMC4yNjY4OTUgMC42MDk3NjYgMC4yNjY4OTUgMS4xMzQ3NyAwLjU5MTg5NSAxLjQ1OTc3TDQuNDE2ODkgNS4yODQ3N0M0Ljc0MTg5IDUuNjA5NzcgNS4yNjY4OSA1LjYwOTc3IDUuNTkxODkgNS4yODQ3N0w5LjQxNjg5IDEuNDU5NzdDOS43NDE4OSAxLjEzNDc3IDkuNzQxODkgMC42MDk3NjYgOS40MTY4OSAwLjI4NDc2NkM5LjA5MTg5IC0wLjAzMTkwMSA4LjU1ODU2IC0wLjA0MDIzNDQgOC4yMzM1NiAwLjI4NDc2NloiIGZpbGw9IiM2QjcyODAiLz4KPC9zdmc+Cg==);
            background-repeat: no-repeat, repeat;
            background-position: right 1.5rem center;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-size: 12px 14px;
            height: 39px;
            &.circle {
                border-radius: 18.1064px;
            }
            &:focus {
                border: 1px solid #03b071;
                box-shadow: none !important;
                color: #03b071;
            }
            &:active {
                border: 1px solid #03b071;
                color: #03b071;
            }
        }
    }
    &-red {
        &-select {
            box-sizing: border-box;

            /* Auto layout */

            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 13px;

            height: 34px;

            /* white */

            background: #ffffff;
            /* Default/gray/300 */

            border: 1.00591px solid #f87171;
            /* shadow/sm */
            color: #f87171;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);

            /* Inside auto layout */
            flex: none;
            order: 1;
            align-self: stretch;
            flex-grow: 0;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik04LjIzMzU2IDAuMjg0NzY2TDUuMDAwMjMgMy41MTgxTDEuNzY2ODkgMC4yODQ3NjZDMS40NDE4OSAtMC4wNDAyMzQ0IDAuOTE2ODk0IC0wLjA0MDIzNDQgMC41OTE4OTUgMC4yODQ3NjZDMC4yNjY4OTUgMC42MDk3NjYgMC4yNjY4OTUgMS4xMzQ3NyAwLjU5MTg5NSAxLjQ1OTc3TDQuNDE2ODkgNS4yODQ3N0M0Ljc0MTg5IDUuNjA5NzcgNS4yNjY4OSA1LjYwOTc3IDUuNTkxODkgNS4yODQ3N0w5LjQxNjg5IDEuNDU5NzdDOS43NDE4OSAxLjEzNDc3IDkuNzQxODkgMC42MDk3NjYgOS40MTY4OSAwLjI4NDc2NkM5LjA5MTg5IC0wLjAzMTkwMSA4LjU1ODU2IC0wLjA0MDIzNDQgOC4yMzM1NiAwLjI4NDc2NloiIGZpbGw9IiM2QjcyODAiLz4KPC9zdmc+Cg==);
            background-repeat: no-repeat, repeat;
            background-position: right 1.5rem center;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background-size: 12px 14px;
            height: 39px;
            &.circle {
                border-radius: 18.1064px;
            }
            &:focus {
                border: 1px solid #f87171;
                box-shadow: none !important;
                color: #f87171;
            }
            &:active {
                border: 1px solid #f87171;
                color: #f87171;
            }
        }
    }
}

.theme {
    &-form {
        &-control {
            display: flex;
            flex-flow: row;
            cursor: pointer;
            align-items: center;
            padding: 9px 12px 9px 0px;
            height: 38px;
            /* white */

            background: #ffffff;
            /* Default/gray/300 */

            border: 1px solid #d1d5db;
            /* shadow/sm */

            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
            border-radius: 18px;
            overflow: hidden;
            &-pre {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 9px 9px 9px 16px;

                width: auto;
                height: 38px;

                /* #F8F5FF */

                background: #f8f5ff;
                border-top: none;
                border-bottom: none;
                border-left: none;
                border-right: 1px solid #d1d5db;

                box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.05);
                border-radius: 19px;
                &.white {
                    background: white;
                    border: none;
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14.0828px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    /* Default/gray/500 */

                    color: #6b7280;
                }
                span {
                    /* text-sm/leading-5/font-medium */

                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    /* Purple */

                    color: #8d78fb;
                }
            }
            &-post {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 9px 16px 9px 9px;

                width: auto;
                height: 38px;

                margin-right: -12px;

                /* #F8F5FF */

                background: #f8f5ff;
                border-top: none;
                border-bottom: none;
                border-left: none;
                border-right: 1px solid #d1d5db;

                box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.05);
                border-radius: 19px;
                &.white {
                    background: white;
                    border: none;
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14.0828px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    /* Default/gray/500 */

                    color: #6b7280;
                }
                &.transparent {
                    background: transparent;
                    border: none;
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14.0828px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    /* Default/gray/500 */

                    color: #6b7280;
                }
                span {
                    /* text-sm/leading-5/font-medium */

                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    /* Purple */

                    color: #8d78fb;
                }
                .dropdown {
                    .post {
                        border: none !important;
                        box-shadow: none !important;
                        padding: 0px 0px 0 8px;
                        height: 34px;
                    }
                }
            }
            &-input {
                background: #eff2f7;
                border: none;
                box-shadow: none;
                flex: 1;
                .form-control {
                    border: none;
                    height: 100% !important;
                    box-shadow: none;
                }
                .input {
                    border: none;
                    height: 100% !important;
                    box-shadow: none;
                }
            }
            &-pretext {
                flex: 1;
            }
            &-posttext {
                margin-left: 6px;
                align-items: center;
                justify-content: center;
                span {
                    // font-family: "Montserrat";
                    // font-style: normal;
                    // font-weight: 700;
                    // font-size: 16px;
                    // line-height: 20px;
                    // /* identical to box height */

                    // color: #000000;
                    /* text-sm/leading-5/font-normal */

                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    /* gray/500 */

                    color: #6b7280;
                }
            }
            &-icon {
                padding: 9px 13px;
                gap: 4px;

                width: 46px;
                height: 38px;

                background: #ffffff;
                border-radius: 6px;
                font-size: 20px;
                color: green;
            }
            &.visible {
                .theme-form-control-input {
                    border-radius: 18px;
                    input {
                        padding: 7px 12px !important;
                        border-radius: 18px;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
// Labels
.label-header-color {
    color: $label-header-color;
}
.label-modal-choose-title {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 31px;
    /* identical to box height, or 97% */

    text-align: center;

    /* Default/gray/700 */

    color: #374151;
}

.label-example {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */

    /* Violet */

    color: #5831c5;
}

.form-group {
    label {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        /* #100820 */

        color: #100820;
    }
}
// Tabs

// button
.theme-button {
    &-primary {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 13px 25px;

        height: 50px;

        /* #A066FF */

        background: $primary;
        /* shadow/sm */
        color: white !important;

        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 25px;
        :hover {
            color: white !important;
        }
        &.square {
            border-radius: 0px;
        }
        &.no-hov {
            &:hover {
                color: white !important;
                background: $primary !important;
            }
        }
    }
    &-secundary {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 13px 25px;

        height: 50px;

        /* #A066FF */
        color: white !important;

        background: $secundary;
        /* shadow/sm */

        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 25px;
        :hover {
            color: white !important;
        }
        &.mobile {
            display: none;
        }
        &.claim {
            border-radius: 12px;
            font-size: 16px;
            height: 40px;
            padding: 12px 12px;
        }
    }
    &-third {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 13px 25px;

        height: 50px;

        /* #A066FF */
        color: white !important;

        background: $background-violet;
        /* shadow/sm */

        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 25px;
        :hover {
            color: white !important;
        }
        &.mobile {
            display: none;
        }
    }
    &-white {
        &-primary {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 9px 17px;

            width: auto;

            height: 50px;
            /* #F8F5FF */

            background: white;
            /* Lilac */

            border: 1px solid #dcd3ff;
            /* shadow/sm */

            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
            border-radius: 25px;
            /* text-base/leading-6/font-medium */

            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            /* Purple */

            color: #8d78fb !important;
            :hover {
                color: #8d78fb !important;
            }
        }
    }
    &-transparent {
        &-primary {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 9px 17px;

            width: auto;

            height: 50px;
            /* #F8F5FF */

            background: transparent;
            /* Lilac */

            border: 1px solid #dcd3ff;
            /* shadow/sm */

            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
            border-radius: 25px;
            /* text-base/leading-6/font-medium */

            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height, or 150% */

            /* Purple */

            color: #8d78fb !important;
            :hover {
                color: #8d78fb !important;
            }
        }
    }
    &-update {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 50px;

        /* Green alien */

        background: #cbfb47;
        /* shadow/sm */

        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 25px;
        /* text-base/leading-6/font-medium */

        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        /* #100820 */

        /* text-base/leading-6/font-medium */

        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        /* #100820 */

        color: #100820;
        &.mobile {
            display: none;
        }
    }
    &-upload {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 9px 17px;

        width: auto;
        min-width: 135px;

        height: 50px;
        /* #F8F5FF */

        background: #f8f5ff;
        /* Lilac */

        border: 1px solid #dcd3ff;
        /* shadow/sm */

        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 25px;
        /* text-base/leading-6/font-medium */

        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */

        /* Purple */

        color: #8d78fb !important;
        :hover {
            color: #8d78fb !important;
        }
        &.mobile {
            display: none;
        }
    }
    &-new {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 9px 13px 9px 11px;
        gap: 8px;

        width: 205px;
        height: 42px;

        /* #833CFC */

        background: #833cfc;
        /* shadow/sm */

        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 25px;

        margin-bottom: 12px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height, or 100% */

        /* #F8F5FF */

        color: #f8f5ff !important;

        :hover {
            color: white !important;
        }
        i {
            background: #ffffff;
            font-size: 22px;
        }
        &.mobile {
            display: none;
        }
    }
    &-collaborator {
        /* Auto layout */

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 9px 13px 9px 11px;
        gap: 8px;

        width: 150px;
        height: 42px;

        /* #F8F5FF */

        background: #f8f5ff;
        /* Lilac */

        border: 1px solid #dcd3ff;
        /* shadow/sm */

        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 25px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        /* identical to box height, or 100% */

        /* Purple */

        color: #8d78fb !important;

        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;
        i {
            background: #ffffff;
            font-size: 22px;
        }
        &.mobile {
            display: none;
        }
    }
    &-request {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 32px;
        gap: 8px;

        width: 188px;
        height: 48px;

        /* Lilac */

        background: #dcd3ff;
        /* Lilac */

        border: 1px solid #dcd3ff;
        /* shadow/sm */

        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 25px;
        /* text-sm/leading-4/font-medium */

        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height, or 114% */

        /* #833CFC */

        color: #833cfc;

        i {
            background: #833cfc;
            color: #833cfc;
            font-size: 22px;
        }
    }
    &-view {
        /* Auto layout */

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 9px 11px 9px 13px;
        gap: 8px;

        width: 157px;
        height: 34px;

        /* Lilac */

        background: #dcd3ff;
        /* Lilac */

        border: 1px solid #dcd3ff;
        /* shadow/sm */

        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 25px;

        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        /* identical to box height, or 114% */

        /* #833CFC */

        color: #833cfc;

        i {
            background: #833cfc;
            color: #833cfc;
            font-size: 22px;
        }
    }
}

// Container
.theme-subcontent {
    min-height: calc(100vh - 200px);
}

.container-settings-tab {
    flex-flow: row;
    display: flex;
    &-side {
        min-width: 40%;
        display: flex;
        flex-flow: column;
    }
    &-form {
        /* Default/white */
        flex: 1;
        background: #ffffff;
        /* /shadow/base */

        box-shadow:
            0px 1px 3px rgba(0, 0, 0, 0.1),
            0px 1px 2px rgba(0, 0, 0, 0.06);
        border-radius: 12px;
        flex-flow: column;
        margin: 4px 12px 4px 0px;
        padding: 30px;
        display: flex;
        min-width: 60%;
        &-view {
            display: flex;
            flex-flow: row;
            align-items: baseline;
            &-subdomain {
                cursor: pointer;
                color: black;
                display: flex;
                flex-flow: row;
                margin-left: 1rem;
                a {
                    color: black;
                }
                &-ico {
                    margin-left: 4px;
                }
            }
        }
    }
    &-example {
        height: max-content;
        flex-flow: column;
        margin: 4px 12px;
        display: flex;
        /* Default/white */

        background: #ffffff;
        /* /shadow/base */

        box-shadow:
            0px 1px 3px rgba(0, 0, 0, 0.1),
            0px 1px 2px rgba(0, 0, 0, 0.06);
        border-radius: 12px;
        padding: 32px 25px 15rem;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        &.table {
            padding: 20px 12px;
        }
        &.dialog {
            margin: 0;
            padding: 10px;
        }
        &-table {
            &-title {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */
                color: #100820;
                align-self: flex-start;
            }
        }
        p {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 35px;
            line-height: 41px;
            /* identical to box height */

            /* #100820 */

            color: #100820;
            align-self: flex-start;
        }
        table {
            width: 100%;
            margin-bottom: 2rem;
            th,
            td {
                padding: 12px 20px 12px 0px;
                height: 30px;
                border-top: none;

                max-width: 90px;
                min-width: 90px;
            }
            th {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                /* Purple */

                color: #8d78fb;
                border-bottom: 1px solid #e5e7eb;
            }
            td {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                color: #374151;
            }
        }
        &-custom {
            height: 100%;
            width: 100%;
            position: relative;
            &.dialog {
                min-height: 450px;
                .container-settings-tab-example-stroke {
                    .stoken-name {
                        width: 95px;
                    }
                }
                .container-settings-tab-example-card {
                    left: 83px;
                    width: 152px;
                }
            }
        }
        &-titles {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */

            color: #6b7280;
            z-index: 4;
            &.token-name {
                position: absolute;
                top: 20px;
                left: 0px;
                // border: none;
                // border-top: 1px solid #b2baca;
                // border-right: 1px solid #b2baca;
                // border-radius: 0px 9px 0px 0px;
            }
            &.token-media {
                position: absolute;
                top: 142px;
                left: 0px;
            }
            &.token-description {
                position: absolute;
                top: 296px;
                left: 0px;
            }
            &.external-url {
                position: absolute;
                top: 364px;
                left: 0px;
            }
        }
        &-stroke {
            border: 1px solid #b2baca;
            z-index: 4;
            &.stoken-name {
                position: absolute;
                width: 127.79px;
                height: 21.75px;
                left: 75px;
                top: 29px;

                border: none;
                border-top: 2px solid #b2baca;
                border-right: 2px solid #b2baca;
                border-radius: 0px 9px 0px 0px;
            }
            &.stoken-media {
                position: absolute;
                width: 30px;
                height: 0px;
                left: 75px;
                top: 150px;
                border-radius: 9px;
            }
            &.stoken-description {
                position: absolute;
                width: 30px;
                height: 0px;
                left: 75px;
                top: 308px;
                border-radius: 9px;
            }
            &.sexternal-url {
                position: absolute;
                width: 30px;
                height: 0px;
                top: 370px;
                left: 75px;
                border-radius: 9px;
            }
        }
        &-card {
            position: absolute;
            width: 200px;
            height: 370px;
            left: 101px;
            top: 45.04px;
            background: black;
            border: 1px solid #1d1041;
            border-radius: 9px;
            padding: 4px;
            display: flex;
            flex-flow: column;
            overflow: hidden;
            &-title {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                padding: 6px 2px;
                i {
                    color: #b2baca;
                }
                p {
                    color: white;
                }
                span {
                    color: white;
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;

                    line-height: 20px;
                }
            }
            &-img {
                height: 140px;
                width: 100%;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
            &-buttons {
                display: flex;
                flex-flow: row;
                justify-content: space-between;
                &-card {
                    width: 40%;
                    height: 36px;
                    margin: 6px;
                    background: #5831c5;
                    color: white;
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;

                    line-height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 6px;
                }
            }
            &-description {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 8px;
                line-height: 14px;
                display: flex;
                flex-flow: column;
                height: 98px;

                justify-content: flex-start;
                position: relative;
                &-title {
                    line-height: 15px;
                    color: #b2baca;
                }
                span {
                    color: white;
                    margin-top: 1px;
                }
                &-url {
                    color: #5831c5 !important;
                    display: flex;
                    justify-items: flex-end;
                    position: absolute;
                    bottom: 0;
                    padding-bottom: 4px;
                }
            }
            &-bottom {
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                img {
                    width: 100%;
                }
            }
        }
    }
}
@media screen and (max-width: 600px) {
    .container-settings-tab {
        flex-flow: column;
    }
}
.container-token {
    &-filter {
        /* Auto layout */

        display: flex;
        flex-direction: column;
        // align-items: flex-start;
        padding: 30px;
        gap: 15px;

        /* Default/white */

        background: #fff;
        /* /shadow/base */

        box-shadow:
            0px 1px 3px rgba(0, 0, 0, 0.1),
            0px 1px 2px rgba(0, 0, 0, 0.06);
        border-radius: 12px;
    }
    &-table {
        /* Default/white */
        padding: 30px;
        background: #ffffff;
        /* /shadow/base */

        box-shadow:
            0px 1px 3px rgba(0, 0, 0, 0.1),
            0px 1px 2px rgba(0, 0, 0, 0.06);
        border-radius: 12px;
    }
    &-nowrap {
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
        text-overflow: ellipsis;
    }
}
.container-deploy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    gap: 30px;
    /* Default/white */

    background: #ffffff;
    /* /shadow/base */

    box-shadow:
        0px 1px 3px rgba(0, 0, 0, 0.1),
        0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
}
.container-create {
    padding: 30px;
    gap: 30px;

    /* Default/white */

    background: #ffffff;
    /* /shadow/base */
    //     width: 100vw;mobile
    box-shadow:
        0px 1px 3px rgba(0, 0, 0, 0.1),
        0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    &-all {
        display: flex;
        flex-flow: row;
    }
}
.container-video {
    padding: 30px;
    gap: 30px;

    /* Default/white */

    background: #ffffff;
    /* /shadow/base */

    box-shadow:
        0px 1px 3px rgba(0, 0, 0, 0.1),
        0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    flex-flow: column;
    margin-bottom: 11rem;
    &-all {
        display: flex;
        flex-flow: column;
        display: flex;
        flex-flow: column;
        width: auto;
        min-width: 30%;
        margin-left: 3rem;
    }
    p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 29px;

        /* #100820 */

        color: #100820;
    }
    img {
        width: 532.49px;
        // height: 354.99px;
        object-fit: cover;
    }
}
.container-tab-components {
    padding: 30px;
    gap: 30px;
    /* Default/white */

    background: #ffffff;
    /* /shadow/base */

    box-shadow:
        0px 1px 3px rgba(0, 0, 0, 0.1),
        0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    &-card {
        /* Auto layout */
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 17px;

        width: 200px;
        height: 329px;

        background: #ffffff;
        border: 0.635869px solid #e4e4e4;
        border-radius: 7.63043px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;

        margin: 0px 4px;
        overflow: hidden;
        &-img {
            width: 200px;
            height: 200px;
            left: 0px;
            top: 0px;

            background: white;
        }
        &-title {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            /* #100820 */

            color: #100820;
        }
        &-range {
        }
        &-sublabel {
            display: flex;
            flex-flow: row;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            span {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                /* Default/gray/500 */

                color: #6b7280;
            }
            .form-control {
                width: 94px;
                height: 38px;
                display: flex;
                flex: 0;
            }
        }
    }
    &-separator {
        width: 100%;
        height: 0px;
        border: 1px solid #d1d5db;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        display: flex;
    }
}
.container-tab-rarity {
    padding: 30px;
    gap: 30px;

    /* Default/white */

    background: #ffffff;
    /* /shadow/base */

    box-shadow:
        0px 1px 3px rgba(0, 0, 0, 0.1),
        0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
}
.container-tab-moon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* Default/white */

    background: #ffffff;
    /* /shadow/base */
    box-shadow:
        0px 1px 3px rgba(0, 0, 0, 0.1),
        0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 30px;
    gap: 30px;
}

.modalcard {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    &-img {
        display: flex;
        margin: 0px 0px 16px 16px;
        padding: 0px 10px 0px 20px;
        min-height: 100%;
        flex: 2;
        max-width: 50%;
        height: 485px;
        &-picture {
            border-radius: 8px;
            overflow: hidden;
            &.generative {
                height: 100%;
                width: 100%;
                min-height: 280px;
            }
            .card-picture {
                height: 100%;
                width: 100%;
                position: relative;
            }
            img {
                border-radius: 8px;
                height: 100% !important;
                max-height: 100% !important;
                width: 100%;
                background-size: cover;
                object-fit: cover;
            }
        }
    }
    &-body {
        display: flex;
        flex-flow: column;
        flex: 3;
        max-height: 490px;
        overflow: auto;

        &-title {
            height: 30px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 100%;
            color: #110921;
            flex: 1 1;
        }
        &-cards {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            &-item {
                max-width: 140px;
                display: flex;
                flex-flow: column;
                border-radius: 6px;
                padding: 10px;
                border: 1px solid #d1d5db;
                margin: 5px;
                flex: 0 1 30%;
                column-gap: 10px;
                column-width: 150px;
                order: 1;
                align-items: center;
                justify-content: center;
                p {
                    margin: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                &-top {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    /* or 143% */

                    text-align: center;

                    /* #595959 */

                    color: #595959;
                    text-align: center;
                }
                &-middle {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    /* or 143% */

                    text-align: center;

                    /* #595959 */

                    color: black;
                    text-align: center;
                }
                &-bot {
                    font-family: "Roboto";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    /* or 143% */

                    text-align: center;

                    /* #595959 */

                    color: #595959;
                    text-align: center;
                }
            }
        }
    }
    &-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        &.row {
            flex-flow: row;
        }
    }
    &-legendary {
        display: flex;
        flex-flow: column;
        flex: 3;
        max-height: 490px;
        overflow: auto;
        &-title {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 28px;
            line-height: 44px;
            /* identical to box height */

            color: #000000;
            padding: 6px 0px;
        }
        &-subtitle {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 23px;
            /* identical to box height */

            color: #000000;
            padding: 6px 0px;
        }
        &-description {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 23px;
            /* identical to box height */

            color: #000000;
            padding: 6px 0px;
        }
        &-contain {
            display: flex;
            flex-flow: row;
            gap: 20px;
            margin-top: 8px;
            &-body {
                display: flex;
                flex-flow: column;
                flex: 1;
            }
            &-img {
                display: flex;
                flex: 0;
                max-width: 30%;
                width: 100%;
                flex-flow: column;
                img {
                    border: 1px solid #d1d8db;
                    border-radius: 10px;
                }
            }
        }
        &-input {
            &-title {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 28px;
                /* identical to box height */

                color: #495057;
                &.space-top {
                    margin-top: 16px;
                }
            }
            &-row {
                display: flex;
                flex-flow: row;
                gap: 12px;
                .form-group {
                    flex: 1;
                    order: 1;
                }
            }
        }
        &-traits {
            display: flex;
            flex-flow: column;
            &-title {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 20px;
                /* identical to box height */

                padding: 10px 0px;
                color: #495057;
            }
            &-contain {
                display: flex;
                flex-flow: row;
                gap: 10px;
                align-items: center;
            }
            &-input {
                display: flex;
                flex: 1;
            }
            &-switch {
                margin-left: 12px;
                padding-top: 2px;
                align-items: center;
                display: flex;
                &-text {
                    margin-left: 12px;
                    padding-bottom: 4px;
                }
            }
        }
        &-new {
            display: flex;
            flex-flow: row;
            gap: 12px;
            align-items: center;
            margin-top: 5rem;
            &-input {
                flex: 1;
            }
            &-button {
                flex: 1;

                background: #ffffff;
                border: 1px solid #74788d;
                border-radius: 4px;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 700;

                font-size: 16px;
                line-height: 24px;

                margin-right: 52px;

                text-align: center;

                color: #74788d;

                max-width: 40px;
                height: 24px;
                cursor: pointer;
            }
        }
        &-switch {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 12px;
        }
    }
}
.theme-token-card {
    box-sizing: border-box;

    width: 165.33px;
    height: 213.76px;
    min-width: 205px;

    background: #ffffff;
    border: 0.635869px solid #e4e4e4;
    border-radius: 7.63043px;

    &:hover {
        box-shadow: 0 0 3px 4px #e1e8eb;
    }

    a {
        color: #0b0b0b;

        &:hover {
            color: #0b0b0b;
        }
    }
    &.fill {
        border: none;
    }

    &.minting {
        flex: 0;
        width: 116px !important;
        height: 160px !important;
        min-width: 120px !important;
        .card-body {
            img {
                height: 137.15px !important;
                max-height: 137.15px !important;

                top: 0;
                left: 0;
            }
        }
    }
    .card-body {
        padding: 0;
        .card-picture {
            img {
                border-radius: 10px 10px 0 0;
                height: 182.15px;
                max-height: 182.15px;
                background-size: cover;
            }
        }
        .card-picture-float {
            position: absolute;
            top: 4px;
            right: 4px;
        }
        .card-picture-nfy {
            position: absolute;
            top: 4px;
            right: 12px;
        }
    }
    .card-footer {
        .d-flex {
            min-height: 30px !important;
        }
        .card-title {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 11.4456px;
            line-height: 50%;
            /* or 6px */

            color: #110921;
        }
    }
}

.rowcard {
    justify-content: space-between;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    width: 100%;
    height: 70px;

    background: #ffffff;
    border-bottom: 1px solid #d1d5db;
    border-radius: 0px 0px 0px 5px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 12px;
    &-mtitle {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow-x: hidden;
        overflow-y: visible;
        padding-bottom: 6px;
        padding-top: 4px;
        margin-top: 1px;
        margin-bottom: 1px;
    }
    &-img {
        width: 64px;
        height: 70px;

        border-radius: 5px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
        
        img {
            border-radius: 5px;
            height: 99%;
            width: 100%;
            object-fit: cover;
        }
    }
    &-body {
        display: flex;
        flex-flow: column;

        height: 18px;

        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 100%;
        /* identical to box height, or 18px */

        color: #110921;
        flex: 1;
        margin-left: 2rem;
        justify-content: center;
        a {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 100%;
            /* identical to box height, or 18px */

            color: #110921;
        }
        &-sub {
            margin-top: 6px;
            display: flex;
            flex-flow: row;
            white-space: nowrap;
            flex: 1;
            &-banner {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                display: flex;
                align-items: center;

                /* #595959 */

                color: #595959;

                &-dot {
                    background: white;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    margin-right: 8px;

                    &.yellow {
                        background: #ffc701;
                    }
                    &.green {
                        background: green;
                    }
                    &.red {
                        background: red;
                    }
                }
            }
            span {
                margin-left: 10px;
                /* text-sm/leading-5/font-normal */

                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                /* gray/500 */

                color: #6b7280;
                max-width: 138px;
                width: auto;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    &-option {
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        align-self: flex-start;
        &-type {
            /* Auto layout */

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 2px 12px;

            width: 90px;
            height: 24px;

            /* Turquoise */

            border-radius: 12px;
            margin-left: 4px;

            &.generative {
                background: #cefad8;
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                text-align: right;

                /* green/800 */

                color: #065f46;
            }
            &.fixed {
                background: #dbeafe;
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                text-align: right;

                /* blue/800 */
                white-space: nowrap;
                color: #1e40af;
            }
            &.nfy {
                background: #a166ffd9;
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                /* identical to box height, or 143% */

                text-align: right;

                /* blue/800 */
                white-space: nowrap;
                color: $background-violet;
            }
        }
        &-network {
            color: #9ca3af;
            margin-left: auto;
            display: flex;
            align-self: center;
            font-size: 20px;
            height: 22px;
            img {
                vertical-align: bottom;
            }
        }
    }
    &-action {
        display: flex;
    }
    &-parent {
        display: flex;
        padding: 9px 0px 9px 20px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        grid-gap: 8px;
        gap: 8px;
        flex: 1 0 75%;
        max-height: 70px;
        height: 70px;
        &-top {
            display: flex;
            align-items: center;
            grid-gap: 5px;
            gap: 5px;
            align-self: stretch;
            /* flex: 1; */
            justify-content: space-between;
            max-height: 50%;
            a {
                max-height: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &-bottom {
            display: flex;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            justify-content: space-between;
            max-height: 50%;
        }
    }
}

.hauto {
    height: auto;
    max-height: 100px;
}
// header-admin
.header-admin {
    width: 100%;
    height: 30px;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 500;
    p {
        color: white;
        margin: 0;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }
    span {
        color: $update;
        margin-left: 8px;
    }
}

// footer {
.theme-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* height: 30px; */
    flex-flow: row;
    padding: 0px 32px;
    margin-top: 28px;
    &-container {
        border-top: 1px solid $light-purple;
        display: flex;
        width: 100%;
        align-items: center;
        padding: 0px 18px;
    }
    &-group {
        display: flex;
        flex: auto;
        &.end {
            justify-content: flex-end;
        }
        p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            margin: 0;
            color: $label-bottom-color;
            span > a {
                color: $secundary !important;
                cursor: pointer;
            }
        }
        &-help {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            margin: 0;
            color: $label-bottom-color;
            cursor: pointer;
            padding: 20px 12px;
        }
    }
}

// breadcrums
.breadcrumb {
    &-page-title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        line-height: 41px;
        /* identical to box height */

        /* #100820 */

        color: #100820;
    }
}

// modal choose type
.modal-choose {
    &-card {
        display: flex;
        flex-flow: column;
        margin: 32px;
        &-img {
            cursor: pointer;
            width: 194px;
            height: 194px;
            border-radius: 16px;
            overflow: hidden;
            border: 1px solid #d1d5db;
        }
        &-title {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 26px;
            /* identical to box height */

            /* #595959 */

            color: #595959 !important;
            text-align: center;
            margin: 14px 0px;
        }
        &-subtitle {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* or 143% */

            text-align: center;

            /* #595959 */

            color: #595959;
            text-align: center;
        }
    }
}

//  input range
input[type="range"].input-range--custom {
    -webkit-appearance: none;
    width: 100%;
    margin: 4px 0;
    background-image: linear-gradient(to right, a066ff, rga066ff 0%, #f8f5ff 48%);
    border-radius: 20px;
}

input[type="range"].input-range--custom:focus {
    outline: none;
}

input[type="range"].input-range--custom::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
}

input[type="range"].input-range--custom:after {
    width: 20%;
    height: 4px;
    background-color: #a066ff;
}

input[type="range"].input-range--custom::-webkit-slider-thumb {
    height: 16px;
    width: 16px;
    border-radius: 15px;
    background: #f8f5ff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5.5px;
    background: #a066ff;
    border: 2px solid #f8f5ff;
    box-sizing: border-box;
    box-shadow: 0px 0px 3px #00000026;
}

// Card

.modal-card {
    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 9px 9px 15px;
    gap: 15px;

    width: 212px;
    height: 314px;

    background: #f8f5ff;
    border-radius: 16px;
    margin: 6px 6px;
    &:hover {
        box-shadow: 0 0 3px 4px #e1e8eb;
    }
    &-picture {
        width: 194px;
        height: 194px;
        min-width: 194px;
        min-height: 194px;
        border-radius: 16px;
        object-fit: cover;
        overflow: hidden;
    }
    &-title {
        /* 22 */

        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        /* identical to box height */

        /* #595959 */

        color: #595959;
        margin: 0;
    }
    &-subtitle {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */

        text-align: center;

        /* #595959 */

        color: #595959;
        margin: 0;
    }
}

// header
.progress-header {
    display: flex;
    flex-flow: column;
    padding: 0px 14px;
    &-contain {
        width: 100%;
        display: flex;
        flex-flow: row;
        align-items: baseline;
        justify-content: space-between;
    }
    &-labels {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        &-progress {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */

            /* Default/gray/700 */

            color: #374151;
        }
        &-percentage {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */

            text-align: right;

            /* Default/gray/700 */

            color: #5831c5;
        }
        &.primary {
            color: #5831c5;
        }
    }
}

.layers-button {
    border: none;
    z-index: 30;
    font-size: 16px !important;
    padding: 5px 12px !important;
    &:hover {
        background: transparent;
        color: #833cfc;
    }
}
#button-helper {
    background: transparent;
    position: absolute;
    right: 1px;
    height: 100%;
    width: 40px;
    z-index: 31;
    border: none;
}

#button-helper:hover ~ .layers-button {
    background: transparent;
    color: #833cfc;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.card-picture-float {
    position: absolute;
    top: 4px;
    right: 4px;
    display: flex;
    flex-flow: row;
}

.text-rainbow-animation {
    background-image: linear-gradient(to right, white, violet, $light-purple, $primary, $secundary, $background-violet);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: rainbow-animation 0.01s linear infinite;
}

@keyframes rainbow-animation {
    to {
        background-position: 4500vh;
    }
}
.colored-chips,
[class*="colored-chips"] {
    display: flex;
}
.colored-chips {
    flex-flow: column;

    &-item {
        flex-flow: row;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 9px;
        line-height: 10px;

        text-align: center;

        /* #595959 */

        color: #595959;
        text-align: center;
        margin: 2px 6px;
        padding: 2px 10px;
        background: #dadadadb;
        border-radius: 10px;
        overflow: hidden;
        &-close {
            cursor: pointer;
            padding-right: 4px;
            &:hover {
                color: red;
                font-size: 11px;
            }
        }
    }
    &-container {
        flex-flow: row;
        flex-wrap: wrap;
        align-items: center;
    }
}

@media screen and (max-width: 500px) {
    .theme {
        &-form {
            &-control {
                margin-bottom: 8px;
            }
        }
    }
    .theme-button {
        &-secundary {
            &.desktop {
                display: none !important;
            }
            &.mobile {
                display: flex !important;
                width: 100%;
                margin-top: 12px;
            }
        }
        &-update {
            &.desktop {
                display: none !important;
            }
            &.mobile {
                display: flex !important;
                width: 100%;
                margin-top: 12px;
            }
        }
        &-collaborator {
            &.desktop {
                display: none !important;
            }
            &.mobile {
                display: flex !important;
                width: 100%;
                margin-top: 12px;
            }
        }
    }
    .theme-button-collaborator:hover {
        color: #8d78fb !important;
        background: #f8f5ff !important;
        border: 1px solid #dcd3ff !important;
    }

    .container-settings-tab {
        &-form {
            margin: 0;
        }
        &-form-view {
            flex-flow: column;
        }
        &-example.preview {
            display: none;
        }
        &-side {
            align-items: center;
            justify-content: center;
        }
    }
}
