.content {
    padding-top: 110px;
    display: block;
    width: auto;
    background: #f8f5ff;
    min-height: 100vh;
    max-width: 1600px; // al containers max withd laptop lg
    margin-left: auto;
    margin-right: auto;
    .hideOnMobile {
        @media screen and (max-width: 600px) {
            display: none;
        }
    }

    table {
        @media screen and (max-width: 600px) {
            max-width: 95%;
            width: 95%;
        }
    }

    h2 {
        font-size: 2.65em;
        font-weight: 700;
        color: #5c6876;
    }

    h3 {
        letter-spacing: 0.005em;
        font-size: 1.65em;
        font-weight: 400;
        color: #5c6876;
    }

    .paper-heading {
        padding: 8px 24px;
        border-bottom: 1px solid #dcdcdc;
    }

    .configuration-container {
        .configuration-nav {
            padding: 16px 0;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    a {
                        display: block;
                        padding: 12px 8px;
                        color: #4d4d4d;

                        &:hover {
                            text-decoration: none;
                            background: #eeeef3;
                        }
                    }
                }
            }
        }
    }
}

.page-title-box {
    // padding-bottom: $grid-gutter-width;
    padding-bottom: 8px;
    .page-title {
        @media only screen and (max-device-width: 1024px) {
            font-size: 16px;
        }

        i {
            margin-right: 16px;
        }
    }

    .breadcrumb {
        background-color: transparent;
        padding: 0;

        @media only screen and (max-device-width: 1024px) {
            font-size: 10px;
        }
    }

    h4 {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px !important;
    }
}

.responsive {
    max-width: 100%;
}

.ac_results {
    border: 1px solid #c7c7c7;
    background-color: white;
    overflow: hidden;
    z-index: 99999;
    padding: 0px;

    ul {
        width: 100%;
        list-style-position: outside;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin: 0px;
            padding: 4px 8px;
            cursor: default;
            display: block;
            line-height: 1.5;
            overflow: hidden;
        }
    }
}

.centered {
    width: 95%;
    margin: 0 auto;
}

.card {
    &.card-borderless {
        padding: 0;
        width: 260px;
        max-height: 311px;
        margin: 12px;
        display: inline-block;
        border: 1px solid #e4e4e4;
        border-radius: 12px;
        cursor: pointer;
        &:hover {
            box-shadow: 0 0 3px 4px #e1e8eb;
        }

        a {
            color: #0b0b0b;

            &:hover {
                color: #0b0b0b;
            }
        }

        .card-body {
            padding: 0;

            .card-picture {
                img.fixed {
                    border-radius: 10px 10px 0 0;
                    height: 200px;
                    max-height: 200px;
                    background-size: cover;
                }
                img.generative {
                    border-radius: 10px 10px 0 0;
                    height: 200px;
                    max-height: 200px;
                    background-size: cover;
                }
                img, video {
                    border-radius: 10px 10px 0 0;

                    max-height: 255px;
                    height: 255px;
                    background-size: cover;
                }
                &-empty {
                    border-radius: 10px 10px 0 0;
                    background: #dcd3ff;
                    height: 259px;
                    width: 100%;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    &-icon {
                        font-size: 80px;
                        color: black;
                    }
                }
            }
        }

        .card-footer {
            max-height: 55px;
            padding: 0 1.25rem;
            .dropdown .dropdown-menu a {
                color: #0b0b0b;
            }
            .subrow {
                display: flex;
                flex-flow: row;
                align-items: baseline;
                justify-content: space-between;
                width: 100%;
                margin-top: -8px;
            }

            .d-flex {
                min-height: 12px !important;
                padding-top: 8px;
                flex-flow: column;
                align-items: flex-start;
                justify-content: flex-start;
            }
        }
        .card-title {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 50%;
            /* or 9px */

            color: #110921;
        }
    }
}
