.jsmodal-dialog {
    position: relative;
    top: "50%";
    left: "50%";
    right: "auto";
    bottom: "auto";
    margin-right: "-50%";
    transform: "translate(-50%, -50%)";
    background: #fff;
    width: 40vw;
    border: none;
    box-shadow: 0px 0px 27px 1px #a0a09f;
    outline: 0;
    border-radius: 8px;
    z-index: 1050;
    &.xxl {
        width: 70%;
    }
    &.yesno {
        width: 25vw;
    }

    .jsmodal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-clip: padding-box;

        // Remove focus outline from opened modal
        outline: 0;

        .jsmodal-header {
            padding: 16px;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
            padding: $modal-header-padding;
            border-bottom: $modal-header-border-width solid $modal-header-border-color;
            @include border-top-radius($modal-content-inner-border-radius);

            .btn-close {
                padding: ($modal-header-padding-y * 0.5) ($modal-header-padding-x * 0.5);
                margin: ($modal-header-padding-y * -0.5) ($modal-header-padding-x * -0.5)
                    ($modal-header-padding-y * -0.5) auto;
            }

            .jsmodal-title {
                margin-bottom: 0;
                line-height: $modal-title-line-height;
            }

            button {
                background-color: transparent;
                background-repeat: no-repeat;
                border: none;
                cursor: pointer;
                overflow: hidden;
                outline: none;
                font-size: 150%;
            }
        }

        .jsmodal-body {
            position: relative;
            // Enable `flex-grow: 1` so that the body take up as much space as possible
            // when there should be a fixed height on `.modal-dialog`.
            flex: 1 1 auto;
            padding: 16px 32px;
            min-height: 80px;
        }

        .jsmodal-footer {
            text-align: right;
            padding: 16px;

            button:nth-child(1) {
                margin-right: 4px;
            }
        }
    }
}
