.navbar-profile {
    .page-title {
        font-size: 1.85em;
    }

    .navbar-left-items {
        display: inline-block;
        vertical-align: top;
        text-align: left;
        width: 50%;
        padding: 0;

        img {
            width: 120px;
        }
    }

    .navbar-profile-actions {
        display: inline-block;
        vertical-align: top;
        text-align: right;
        width: 50%;
    }

    .header-item {
        height: $header-height;
        box-shadow: none !important;
        color: $header-item-color;
        border: 0;
        border-radius: 0px;
        text-align: right;
        width: 100%;

        @media screen and (max-width: 900px) {
            width: 80px;
        }

        &:hover {
            color: $header-item-color;
        }

        .header-profile-user {
            background-color: $gray-300;
            display: inline-block;
            height: 40px;
            width: 40px;
            padding: 3px;
            margin-right: 8px;
        }

        .header-item-profile-name {
            display: inline-block;
            vertical-align: top;
            padding-top: 12px;
            margin-right: 8px;
            font-size: 12px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            margin-right: 8px;
            text-align: right;

            color: #e9ecef;
            @media screen and (max-width: 900px) {
                display: none;
            }
        }

        .header-item-chevron {
            display: inline-block;
            vertical-align: super;
            padding-top: 6px;
        }
    }
}
