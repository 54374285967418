[class*='col-'] {
  float: left;
  
  .module {  
    padding: 20px;
    overflow: hidden;
    
    &.center {
      .column {text-align: center}
    }
  }
}

.row {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto;
  text-align: left;
}

.grid-layout {
  max-width: 1170px;
  display: block;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}

.col-1-1 {width: 100%}
.col-1-2 {width: 50%}
.col-1-3 {width: 33.33%}
.col-2-3 {width: 66.66%}
.col-1-4 {width: 25%}
.col-1-5 {width: 20%}
.col-1-8 {width: 12.5%}

@media (max-width: 900px) {
  .col-1-1,
  .col-1-2,
  .col-1-3,
  .col-2-3,
  .col-1-4,
  .col-1-5,
  .col-1-8 {width: 100%}  
}